<template>
    <div
      class="rounded-lg bg-white p-4 sm:p-6 md:p-8"
    >
      <div class="flex flex-col">
        <div class="-flex sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full align-middle sm:px-6 lg:px-8">
            <div class="grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label
                  for="invoice"
                  class="block text-sm font-medium text-gray-700"
                >
                  Catatan
                </label>
                <textarea
                  v-model="order.attributes.notes"
                  id="about"
                  name="about"
                  rows="3"
                  class="field-inset-default relative block w-full max-w-lg rounded-md border border-gray-300 shadow-sm sm:text-sm"
                ></textarea>
                <div class="my-4">
                  <h3 class="text-sm font-semibold">Alamat Stockist</h3>
                  <p class="text-gray-700">
                    {{ order.relationships.destinationOffice.attributes.address }}
                    {{ order.relationships.destinationOffice.attributes.city }}
                  </p>
                  <p class="text-gray-700">
                    {{ order.relationships.destinationOffice.attributes.phone }}
                  </p>
                </div>
              </div>
              <div class="flex w-full justify-end sm:col-span-3">
                <fieldset class="flex mt-4 flex-col gap-y-4">
                  <div class="flex">
                    <div class="flex h-5 items-center">
                      <input
                        id="cetak-faktur"
                        aria-describedby="cetak-faktur"
                        name="cetak-faktur"
                        type="checkbox"
                        value="true"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="cetak-faktur"
                        class="font-medium text-gray-700"
                        >Cetak Faktur</label
                      >
                      <p id="cetak-faktur" class="text-gray-500">
                        Simpan dan cetak faktur secara langsung
                      </p>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="flex h-5 items-center">
                      <input
                        id="cetak-faktur"
                        aria-describedby="cetak-faktur"
                        name="cetak-faktur"
                        type="checkbox"
                        value="true"
                        class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                      />
                    </div>
                    <div class="ml-3 text-sm">
                      <label
                        for="cetak-faktur"
                        class="font-medium text-gray-700"
                        >Download Faktur</label
                      >
                      <p id="cetak-faktur" class="text-gray-500">
                        Simpan dan download faktur ke komputer anda
                      </p>
                    </div>
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            required: true
        },
    },
    emits: ['input'],
    computed: {
        order: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        }
    },
}
</script>