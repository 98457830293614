<template>
  <div class="-flex sm:-mx-6 lg:-mx-8">
    <div
      class="inline-block min-w-full max-w-full align-middle sm:px-6 lg:px-8"
    >
      <Datatable
        :total="getOrders.meta.page.total"
        :perPage="getOrders.meta.page.perPage"
        :currentPage="getOrders.meta.page.currentPage"
        @pagechanged="onPageChangeOrder"
        :paginated="false"
        :is-empty="!getOrders.data.length"
      >
        <template v-slot:thead>
          <thead class="bg-blue-600">
            <tr>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Nomor PO
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Tanggal PO
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Total Produk
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Total Berat
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Total Harga
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Status Laporan
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Status Faktur
              </th>
              <th
                scope="col"
                class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                STATUS
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody class="relative">
          <div v-if="isLoading" class="flex items-center text-center">
            <loading></loading>
          </div>
          <tbody v-if="getOrders.data.length">
            <tr
              class="cursor-pointer bg-white hover:bg-blue-100"
              v-for="(data, dataIdx) in getOrders.data"
              :key="data.id"
              :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
              @click.prevent="onDetail(data)"
            >
              <td
                class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
              >
                <div class="text-sm font-bold text-gray-900">
                  {{ data.attributes.origin_code }}
                </div>
                <span class="text-xs">
                  {{ data.attributes.destination_code }}
                </span>
              </td>
              <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
                {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                {{ data.attributes.item_count }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                {{ data.attributes.grand_total_weight }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
              >
                {{ data.attributes.final_price | toCurrency }}
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
              >
                <span
                  v-if="data.attributes.has_bundle"
                  class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                >
                  Selesai
                </span>
                <span
                  v-else
                  class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-700"
                >
                  Belum Selesai
                </span>
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
              >
                <span
                  v-if="data.attributes.is_valid_for_payment"
                  class="inline-flex items-center rounded-full bg-green-100 px-3 py-1 text-sm font-medium text-green-800"
                >
                  Valid
                </span>
                <span
                  v-else
                  class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
                >
                  Draft
                </span>
              </td>
              <td
                class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
              >
                <span
                  class="inline-flex items-center rounded-full bg-blue-100 px-3 py-1 text-sm font-medium text-blue-700"
                >
                  {{ data.attributes.order_status }}
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import ApiService from '@/services/api.service';
import Datatable from '@/components/base/Datatable';
import Loading from '@/components/Loading.vue';
import dayjs from 'dayjs';

export default {
  name: 'Home',
  components: {
    Datatable,
    Loading,
  },

  data() {
    return {
      getOrders: {
        data: [],
        jsonapi: '',
        meta: {
          page: {
            total: 0,
            perPage: 0,
          },
        },
        links: {},
      },
      openDetail: false,
      dataPenjualan: {},
      ordersParams: {
        page: {
          size: 5,
          number: 1,
        },
        filter: {
          search: null,
        },
      },
    };
  },

  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      isLoading: 'orders/getLoading',
      getUser: 'auth/getUser',
      getOfficeCategories: 'office_categories/getAvailableOfficeCategories',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrder: 'orders/fetchOrder',
      fetchOrders: 'orders/fetchOrders',
    }),
    async loadOrders() {
      let officeCategoryId = '';
      let destination_office_category_id = this.getOfficeCategories.filter(
        function (el) {
          return el.attributes.name === 'Stockist Center';
        }
      );
      officeCategoryId = destination_office_category_id[0].id;
      const res = await ApiService.get('api/v1/orders', {
        params: {
          'filter[destination_warehouse_id]': this.getUser.current_warehouse,
          'page[limit]': this.ordersParams.page.size,
          // 'page[number]': this.ordersParams.page.number,
          'filter[search]': this.ordersParams.filter.search,
          'filter[destination_office_category_id]': officeCategoryId,
          include: '',
          'fields[orders]':
            'origin_code,destination_code,createdAt,item_count,grand_total_weight,final_price,has_bundle,is_valid_for_payment,order_status',
        },
      });
      this.getOrders = res.data;
    },
    resetOrdersPage() {
      this.ordersParams.page.number = 1;
    },
    resetOrdersFilter() {
      this.ordersParams.filter.search = null;
    },
    debounceSearch: debounce(function () {
      this.resetOrdersPage();
      this.loadOrders();
    }, 500),
    onRefresh() {
      this.resetOrdersPage();
      this.resetOrderFilter();
      this.loadOrders();

      this.openDetail = false;
    },
    onEdit(id) {
      this.$router.push('restock/tambah-restock/' + id);
    },
    onPageChangeOrder(page) {
      this.ordersParams.page.number = page;

      this.loadOrders();
    },
    onDetail() {
      this.$router.push('gudang/restock');
      window.scrollTo(0, 0);
    },
  },
  created() {
    this.loadOrders();

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
