<template>
  <BaseCard title="Pilih Daftar Faktur Penjualan Yang Akan Dilaporkan">
    <Datatable
      :total="getOrders.meta.page.total"
      :perPage="getOrders.meta.page.perPage"
      :currentPage="getOrders.meta.page.currentPage"
      @pagechanged="onPageChangeOrders"
    >
      <template v-slot:thead>
        <thead class="bg-gray-50">
          <tr>
            <th
              v-if="getOrders.data.length"
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            ></th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              No Faktur
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Kode Stockist
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Tanggal
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Wilayah Harga
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Belanja
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Laporan
            </th>
            <th
              scope="col"
              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
            >
              Selisih
            </th>
          </tr>
        </thead>
      </template>
      <template v-slot:tbody>
        <tbody v-if="getOrders.data.length">
          <tr
            class="bg-white"
            v-for="(data, dataIdx) in getOrders.data"
            :key="data.id"
            :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          >
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              <input
                v-model="checkedOrders"
                @change="onChange"
                :value="data.attributes.order_id"
                name="check_order"
                type="checkbox"
                class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
              />
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-sm font-bold text-gray-700"
            >
              {{ data.attributes.origin_code }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{
                getRelationships(
                  getOrders,
                  data.relationships['destination-office'].data.id
                ).attributes.code
              }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{ data.attributes.createdAt | formatDate }}
            </td>
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              {{
                getRelationships(getOrders, data.relationships['area'].data.id)
                  .attributes.name
              }}
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
            >
              {{
                getRelationships(getOrders, data.relationships['order'].data.id)
                  .attributes.grand_total_price | toCurrency
              }}
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
            >
              {{
                getRelationships(getOrders, data.relationships['order'].data.id)
                  .attributes.grand_total_report_price | toCurrency
              }}
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
            >
              {{ getSelisih(data) | toCurrency }}
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr class="bg-white">
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              Data tidak ditemukan
            </td>
          </tr>
        </tbody>
        <tfoot class="bg-gray-50">
          <tr>
            <td
              scope="col"
              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
              :colspan="getOrders.data.length ? '5' : '4'"
            >
              Total
            </td>
            <td scope="col" class="px-6 py-3 text-right text-sm text-gray-500">
              {{ totalSelectedOrder.total_belanja | toCurrency }}
            </td>
            <td scope="col" class="px-6 py-3 text-right text-sm text-gray-500">
              {{ totalSelectedOrder.total_laporan | toCurrency }}
            </td>
            <td scope="col" class="px-6 py-3 text-right text-sm text-gray-500">
              {{ totalSelectedOrder.total_selisih | toCurrency }}
            </td>
          </tr>
        </tfoot>
      </template>
    </Datatable>
  </BaseCard>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OrderBundlesCheckboxTable',
  props: {},
  data() {
    return {
      checkedOrders: [],
    };
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
    }),
    totalSelectedOrder() {
      const selectedOrders = this.getOrders.included
        ?.filter((it) => it.type == 'orders')
        .filter((order) =>
          this.checkedOrders.includes(order.id)
        );

      if (!selectedOrders?.length) {
        return {
          total_belanja: 0,
          total_laporan: 0,
          total_selisih: 0,
        };
      }

      return {
        total_belanja: selectedOrders.reduce(
          (total, order) => (total += order.attributes.grand_total_price),
          0
        ),
        total_laporan: selectedOrders.reduce(
          (total, order) =>
            (total += order.attributes.grand_total_report_price),
          0
        ),
        total_selisih: selectedOrders.reduce(
          (total, order) => (total += (order.attributes.grand_total_price - order.attributes.grand_total_report_price)),
          0
        ),
      };
    },
  },
  components: {
    // Your child components here
  },
  methods: {
    getRelationships(resource, id) {
      let data = resource.included.find(function (el) {
        return el.id === id;
      });
      return data;
    },
    onChange() {
      this.$emit('change', this.checkedOrders);
    },
    getSelisih(order) {
      return Math.abs(
        this.getRelationships(
          this.getOrders,
          order.relationships['order'].data.id
        ).attributes.grand_total_price -
          this.getRelationships(
            this.getOrders,
            order.relationships['order'].data.id
          ).attributes.grand_total_report_price
      );
    },
    onPageChangeOrders(page) {
      this.$emit('page-change', page);
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
