<template>
  <div class="mx-auto my-4 space-y-5 px-4 sm:px-4 lg:px-8">
    <loading v-if="loading.visible" :text="loading.text" />

    <OrderCreateForm
      :area="selectedArea"
      v-model="order"
      v-on:change-address="setupArea"
      v-on:change-order-shipment="setupArea"
      v-on:change-destination="setupArea"
    />

    <template v-if="canAddProduct">
      <OrderDetailTable
        :create-order-payload="{
          order_type: 'sale',
          order_shipment: order.attributes.order_shipment,
          notes: order.attributes.notes,
          is_ppn_included: true,
          is_pre_order: false,
        }"
        :origin-warehouse="{
          id: order.relationships.originWarehouse.id,
        }"
        :destination-warehouse="{
          id: order.relationships.destinationWarehouse.id,
        }"
        :origin-office="{
          id: order.relationships.originOffice.id,
          code: order.relationships.originOffice.attributes.code,
        }"
        origin-office-category-type="Stockist Center"
        :destination-office="{
          id:
            order.relationships.destinationOffice.attributes.office_id ??
            order.relationships.destinationOffice.id,
          code: rawDestinationOfficeCode,
        }"
        :buyer-type="{
          id: order.relationships.buyerType.id,
          code: order.relationships.buyerType.attributes.code,
        }"
        :area="{
          id: order.relationships.area.id,
          code: order.relationships.area.attributes.code,
        }"
        :order-id="order.id"
        :min-grand-total="minOrderSpend"
        :with-cashback-warning="order.attributes.order_shipment !== 'delivery'"
        v-model="orderDetails"
        v-on:order-created="handleCreatedOrder"
        v-on:created="handleCreatedOrderDetail"
        v-on:order-updated="handleUpdatedOrder"
      />

      <OrderPromoList class="mb-5" :order-details="orderDetailsForPromoList" :product-bundles="productBundles" @change-selected-bundles="onChangeSelectedBundles" @change-choosen-bonuses="onChangeChoosenBonuses" />

      <!-- Order Details Data -->
      <div class="mb-5">
        <OrderSummary
          :totalbarang="orderDetails.length"
          :with-dpp="false"
          :with-ppn="false"
          :totalharga="totalPrice"
          :cashback="cashback"
          :with-cashback="getOrder.data.attributes.order_shipment != 'delivery'"
          :balance="0"
        />
      </div>

      <div
        v-if="orderDetails.length > 1"
        class="space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
      >
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Metode Pembayaran
          </h3>
        </div>
        <PaymentMethodInlineForm
          :payment-id="getIncludedByType(getOrder, 'payments')[0].id"
          auto-store
          v-model="paymentMethodsForm"
        />
      </div>

      <OrderCreatePrintFormVue v-model="order" />
      <OrderCreateActionForm
        cancel-redirect="/penjualan/transaksi-penjualan"
        :total-price="totalPrice"
        :min-order-spend="minOrderSpend"
        :total-payment-amount="totalPaymentAmount + cashback"
        must-match-payment-amount
        v-on:process="handleProcessOrder"
      />

      <OrderDetailModal
        :visible="success"
        :order="getOrder.data"
        :editable="true"
        :deleteable="false"
        :validationIgnorePaymentStatus="true"
        with-bonus
        @close="handleClose"
        @edit="success = false"
        @refresh="handleClose"
      />
    </template>
  </div>
</template>

<script>
import OrderCreateForm from '@/components/order/create/OrderCreateForm.vue';
import OrderDetailTable from '@/components/order/create/OrderDetailTable.vue';
import PaymentMethodInlineForm from '@/components/payment/PaymentMethodInlineForm.vue';
import OrderCreatePrintFormVue from '@/components/order/create/OrderCreatePrintForm.vue';
import OrderCreateActionForm from '@/components/order/create/OrderCreateActionForm.vue';
import OrderDetailModal from '@/components/order/OrderDetailModal.vue';
import OrderSummary from '@/components/order/OrderSummary.vue';
import { orderCreateMixin } from '@/mixins/order/order-create.mixin';
import { loadingMixin } from '@/mixins/loading/loading.mixin';
import { paymentMethodFormMixin } from '@/mixins/payment-method/payment-method-form.mixin';
import { orderDetailsFormMixin } from '@/mixins/order/order-details-form.mixin';
import { mapActions, mapGetters } from 'vuex';
import { cashbackMixin } from '@/mixins/office-category/cashback.mixin';
import { productBundleMixins } from '@/mixins/product-bundle/product-bundle.mixin';
import OrderPromoList from '@/components/promo/OrderPromoList.vue';

export default {
  components: {
    OrderCreateForm,
    OrderDetailTable,
    PaymentMethodInlineForm,
    OrderCreatePrintFormVue,
    OrderCreateActionForm,
    OrderDetailModal,
    OrderSummary,
    OrderPromoList
  },
  mixins: [
    orderCreateMixin,
    loadingMixin,
    paymentMethodFormMixin,
    orderDetailsFormMixin,
    cashbackMixin,
    productBundleMixins
  ],
  data() {
    return {
      success: false,
      selectedBundles: [],
      choosenBonuses: {}
    };
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      me: 'auth/getUser',
      getAll: 'main/getAll',
    }),
    orderDetailsForPromoList() {
      return this.orderDetails
        .filter(item => item.product.id)
        .map(item => ({
          productId: item.product.id,
          qty: item.qty,
          productCode: item.product.code 
        }))
    },
    productBundles() {
      return this.getAll['product-bundles']?.data ?? [];
    },
    cashback() {
      if (this.order.attributes.order_shipment !== 'pickup') {
        return 0;
      }

      const result = this.getCashbackAmount(
        'Stockist Center',
        this.order.relationships.area.attributes.code,
        this.totalPrice
      );
      return !isNaN(result) ? result : 0;
    },
  },
  async created() {
    this.startLoading('Menyiapkan Faktur');

    try {
      // const orderId = this.$route.params.id;
      await Promise.all([
        this.setupMinOrder('min_order_spend_for_stockist'),
        this.setupPaymentMethodTypes({ officeId: this.me.office_id }),
        this.setupOfficeBanks({ officeId: this.me.office_id }),
        this.setupOriginWarehouse({ warehouseId: this.me.current_warehouse }),
        this.setupOriginOffice({ officeId: this.me.office_id }),
        this.$route.params.id
          ? this.setupDetailOrder()
          : this.setupBuyerType(10),
      ]);

      if (this.$route.params.id) {
        this.setOrderDetails();
        this.loadProductBundles(this.$route.params.id);
      }
    } finally {
      this.stopLoading();
    }

    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  methods: {
    ...mapActions({
      fetchOffices: 'offices/fetchOffices',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      fetchAllRelated: 'main/fetchAllRelated',
    }),
    handleCreatedOrder(order) {
      this.order.id = order.id;
      this.order.attributes.is_valid_for_payment = false;

      this.$router.push(`/penjualan/transaksi-penjualan/${order.id}`);
      this.loadProductBundles(order.id);
    },
    handleCreatedOrderDetail() {
      this.pushEmptyPaymentDetailsItem();
    },
    handleUpdatedOrder() {
      this.loadProductBundles(this.$route.params.id);
    },
    handleClose: function () {
      this.$router.push('/penjualan/transaksi-penjualan');
    },
    async handleProcessOrder() {
      this.startLoading('Memproses Faktur');

      const order = await this.validateOrder({
        selected_bundles_id: this.selectedBundles,
        choosen_bonuses: this.choosenBonuses
      });

      if (order) {
        await this.fetchOrder({ id: this.order.id });

        this.success = true;
      }

      this.stopLoading();
    },
    async setupDetailOrder() {
      await this.setupOrder(this.$route.params.id);
      await this.setupPaymentMethods();
    },
    async setupPaymentMethods() {
      await this.fetchPaymentPaymentMethods({
        id: this.getOrder.data.relationships['payments'].data[0].id,
        include: 'office-bank',
      });

      this.setPaymentDetails();
    },

    // promo
    onChangeSelectedBundles(value) {
      this.selectedBundles = [ ...value ]
    },
    onChangeChoosenBonuses(value) {
      this.choosenBonuses = { ...value }
    },
  },
};
</script>
