import { render, staticRenderFns } from "./kasir-table.vue?vue&type=template&id=452f5512&scoped=true"
import script from "./kasir-table.vue?vue&type=script&lang=js"
export * from "./kasir-table.vue?vue&type=script&lang=js"
import style0 from "./kasir-table.vue?vue&type=style&index=0&id=452f5512&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "452f5512",
  null
  
)

export default component.exports