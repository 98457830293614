<template>
  <section>
    <div class="mx-4 space-y-6 pt-2 pb-4 lg:mx-8">
      <div class="space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
        <div class="flex justify-between border-b border-gray-200 pb-5">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Detail Penjualan
          </h3>
          <div v-if="getOrder.data.attributes.cancel_warehouse_description">
            <div
              class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
            >
              Dibatalkan Gudang
            </div>
            <div class="mt-2 text-right text-xs">
              {{ getOrder.data.attributes.cancel_warehouse_description }}
            </div>
          </div>
        </div>
        <div class="grid grid-cols-1 gap-y-3 gap-x-4 sm:grid-cols-6">
          <div class="sm:col-span-6">
            <div
              class="field-inset-default relative w-full"
              :class="{ 'bg-gray-100': is_detail }"
            >
              <label for="name" class="block text-xs font-bold text-gray-700">
                No. Faktur
              </label>
              <input
                v-model="dataPembayaran.no_penjualan"
                :disabled="is_detail"
                ref="focusNoPenjualan"
                type="text"
                name="no_penjualan"
                id="no_penjualan"
                class="input-inset-select"
                :class="{ 'bg-gray-100': is_detail }"
                placeholder="Masukkan No Penjualan yang akan dibayarkan"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="kode_stockist"
                class="block text-xs font-bold text-gray-700"
              >
                Kode Stockist
              </label>
              <input
                v-model="dataPembayaran.kode_stockist"
                readonly
                type="text"
                name="kode_stockist"
                id="kode_stockist"
                class="input-inset-select bg-gray-100"
                placeholder="ex: SCR338"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="nama_pemilik"
                class="block text-xs font-bold text-gray-700"
              >
                Nama Pemilik
              </label>
              <input
                v-model="dataPembayaran.nama_pemilik"
                readonly
                type="text"
                name="nama_pemilik"
                id="nama_pemilik"
                class="input-inset-select bg-gray-100"
                placeholder="ex: AYATULLOH MUHAMMAD DEVA"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="kode_gudang"
                class="block text-xs font-bold text-gray-700"
              >
                Kode Gudang
              </label>
              <input
                v-model="dataPembayaran.kode_gudang"
                readonly
                type="text"
                name="kode_gudang"
                id="kode_gudang"
                class="input-inset-select bg-gray-100"
                placeholder="ex: GD123"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="wilayah_harga"
                class="block text-xs font-bold text-gray-700"
              >
                Wilayah Harga
              </label>
              <input
                v-model="dataPembayaran.wilayah_harga"
                readonly
                type="text"
                name="wilayah_harga"
                id="wilayah_harga"
                class="input-inset-select bg-gray-100"
                placeholder="ex: Wilayah 1"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="total_penjualan"
                class="block text-xs font-bold text-gray-700"
              >
                Total Penjualan
              </label>
              <input
                :value="dataPembayaran.total_penjualan | toCurrency"
                readonly
                type="text"
                name="total_penjualan"
                id="total_penjualan"
                class="input-inset-select bg-gray-100"
                placeholder="ex: 5.000.000"
              />
            </div>
          </div>
          <div class="sm:col-span-3">
            <div class="field-inset-default relative bg-gray-100">
              <label
                for="metode_pengiriman"
                class="block text-xs font-bold text-gray-700"
              >
                Metode Pengiriman
              </label>
              <input
                :value="
                  getOrder.data.attributes.order_shipment == 'delivery'
                    ? 'Dikirim'
                    : 'Diambil'
                "
                readonly
                type="text"
                name="total_penjualan"
                id="total_penjualan"
                class="input-inset-select bg-gray-100"
                placeholder="ex: 5.000.000"
              />
            </div>
          </div>
        </div>
      </div>

      <!-- Start Metode Pembayaran -->
      <div
        v-if="
          !dataPembayaran.is_paid && metodePembayaran.length && isPaymentsValid
        "
        class="space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"
      >
        <div class="flex items-center justify-between">
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Tambah Invoice
          </h3>
          <button
            @click="addField"
            type="button"
            class="bg-white-600 rounded-md border border-gray-300 py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
          >
            Tambah Metode
          </button>
        </div>

        <!-- Informasi Saldo Pembayaran Yang Digunakan (jika belum ada pembayaran) -->
        <div>
          <div
            class="flex w-full flex-row gap-x-4 gap-y-2 pb-4"
            v-if="!hasInvoices && dataPembayaran.saldo_order_awal > 0"
          >
            <div class="relative w-full">
              <div class="field-inset-default">
                <label
                  for="metode_order"
                  class="block text-xs font-bold text-gray-700"
                >
                  Metode Pembayaran
                </label>
                <input
                  type="text"
                  name="metode_pembayaran"
                  id="metode_pembayaran"
                  class="input-inset-select"
                  autocomplete="off"
                  value="Saldo Pembayaran"
                  disabled
                />
              </div>
            </div>
            <div class="relative w-full">
              <div class="field-inset-default">
                <label
                  for="metode_order"
                  class="block text-xs font-bold text-gray-700"
                >
                  Nominal Pembayaran
                </label>
                <input
                  type="text"
                  name="metode_pembayaran"
                  id="metode_pembayaran"
                  class="input-inset-select"
                  autocomplete="off"
                  :value="usedOrderBalance | toCurrency"
                  disabled
                />
              </div>
            </div>
          </div>
          <!-- End Informasi Saldo Pembayaran Yang Digunakan -->

          <div
            class="flex w-full flex-row gap-x-4 gap-y-2 pb-4"
            v-for="(input, index) in metodePembayaran"
            :key="`metode-${index}`"
          >
            <div class="w-full">
              <div class="field-inset-default relative">
                <label
                  for="nominal_pembayaran"
                  class="block text-xs font-bold text-gray-700"
                >
                  Metode Pembayaran
                </label>
                <select
                  v-model="metodePembayaran[index].jenisPembayaran"
                  type="text"
                  name="nominal_pembayaran"
                  id="wilayah_harga"
                  class="input-inset-select"
                  placeholder="Pilih Jenis Pembayaran"
                  v-on:change="handleChangeMethod(index)"
                >
                  <option :value="null">Pilih Metode Pembayaran</option>
                  <option
                    v-for="method in paymentMethods"
                    :key="method.id"
                    :value="{
                      name: method.name,
                      value: method.value,
                    }"
                    :selected="{
                      name: metodePembayaran[index].jenisPembayaran?.name
                        ? metodePembayaran[index].jenisPembayaran.name
                        : null,
                      value: metodePembayaran[index].jenisPembayaran?.value
                        ? metodePembayaran[index].jenisPembayaran.value
                        : null,
                    }"
                  >
                    {{ method.name }}
                  </option>
                </select>
              </div>
            </div>
            <template
              v-if="metodePembayaran[index].jenisPembayaran?.name === 'Bank'"
            >
              <div class="w-full">
                <div class="field-inset-default relative">
                  <label
                    for="bank"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Pilih Bank
                  </label>
                  <select
                    v-model="metodePembayaran[index].officeBank"
                    type="text"
                    name="bank"
                    id="bank"
                    class="input-inset-select"
                    placeholder="Pilih Jenis Pembayaran"
                  >
                    <option :value="null">Pilih Bank</option>
                    <option
                      v-for="officeBank in getBanksByOffice.data"
                      :key="officeBank.id"
                      :value="{
                        id: officeBank.id,
                        name: officeBank.bank_name,
                      }"
                    >
                      {{ officeBank.attributes.bank_name }} ({{
                        officeBank.attributes.account_number
                      }})
                    </option>
                  </select>
                </div>
              </div>
            </template>
            <template
              v-if="
                metodePembayaran[index].jenisPembayaran?.name === 'Bank' &&
                metodePembayaran[index].officeBank?.id
              "
            >
              <div class="w-full">
                <div class="field-inset-default relative">
                  <label
                    for="bank"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Tanggal Transfer
                  </label>
                  <input
                    v-model="metodePembayaran[index].date"
                    ref="tanggal_transfer"
                    type="datetime-local"
                    name="payment_date"
                    id="payment_date"
                    class="input-inset-select"
                  />
                </div>
              </div>
            </template>
            <template
              v-if="
                !(metodePembayaran[index].jenisPembayaran?.name == 'Bank') ||
                (metodePembayaran[index].jenisPembayaran?.name == 'Bank' &&
                  metodePembayaran[index].officeBank?.id)
              "
            >
              <div
                class="relative w-full"
                v-if="metodePembayaran[index].jenisPembayaran"
              >
                <div class="field-inset-default">
                  <label
                    for="nama_pemilik"
                    class="block text-xs font-bold text-gray-700"
                  >
                    Nominal Pembayaran
                  </label>
                  <input
                    ref="nominal"
                    @input="(e) => handleCurrency(e, index)"
                    type="text"
                    name="nama_pemilik"
                    id="nama_pemilik"
                    class="input-inset-select"
                    placeholder="Masukkan Nominal Pembayaran"
                    autocomplete="off"
                  />
                </div>
                <button
                  v-if="metodePembayaran.length > 1"
                  @click="removeField(index)"
                  type="button"
                  class="focus:outline-none absolute inset-y-0 -top-2 -right-2 flex h-5 w-5 items-center rounded-full border border-transparent bg-red-300 p-1 text-white shadow-sm hover:bg-red-500 focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-3 w-3"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </button>
              </div>
            </template>
          </div>

          <div class="grid grid-cols-6 justify-between gap-4">
            <div class="col-span-3">
              <div class="field-inset-default">
                <label
                  for="catatan"
                  class="block text-xs font-bold text-gray-700"
                >
                  Deskripsi Pembayaran
                </label>
                <input
                  v-model="dataPembayaran.description"
                  type="text"
                  name="deskripsi"
                  id="deskripsi"
                  class="input-inset-select"
                  placeholder="Masukkan Deskripsi Pembayaran(opsional)"
                />
              </div>
            </div>
            <div class="col-span-3">
              <div class="field-inset-default bg-gray-100">
                <label
                  for="nama_pemilik"
                  class="block text-xs font-bold text-gray-700"
                >
                  Total Pembayaran
                </label>
                <input
                  :value="Math.abs(getTotalPembayaran) | toCurrency"
                  readonly
                  type="text"
                  name="nama_pemilik"
                  id="nama_pemilik"
                  class="input-inset-select bg-gray-100"
                  placeholder="Masukkan Nominal Pembayaran"
                />
              </div>
            </div>
          </div>

          <div class="my-4 flex justify-end">
            <div class="flex">
              <div class="flex h-5 cursor-pointer items-center">
                <input
                  id="cetak-faktur"
                  aria-describedby="cetak-faktur"
                  name="cetak-faktur"
                  type="checkbox"
                  value="true"
                  v-model="cetakPdf.cetak"
                  class="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>
              <div class="ml-3 text-sm">
                <label for="cetak-faktur" class="font-medium text-gray-700">
                  Cetak Faktur
                </label>
                <p id="cetak-faktur" class="text-gray-500">
                  Simpan dan cetak faktur secara langsung
                </p>
              </div>
            </div>
          </div>

          <div class="flex justify-end">
            <base-button
              @click="addPayment"
              :disabled="isPaymentMethodEmpty"
              id="simpan_pembayaran"
            >
              Validasi
            </base-button>
          </div>
          <!-- End Metode Pembayaran -->
          <div class="grid grid-cols-6 justify-between gap-4 pt-4">
            <div class="col-span-3"></div>
            <div v-if="selisihPembayaran !== 0" class="col-span-3">
              <div class="field-inset-default bg-gray-100">
                <label
                  for="nama_pemilik"
                  class="block text-xs font-bold text-gray-700"
                >
                  {{ selisihPembayaran > 0 ? 'Kekurangan' : 'Kelebihan' }}
                </label>
                <input
                  :value="Math.abs(selisihPembayaran) | toCurrency"
                  readonly
                  type="text"
                  name="nama_pemilik"
                  id="nama_pemilik"
                  class="input-inset-select bg-gray-100"
                  placeholder="Masukkan Nominal Pembayaran"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8">
        <div>
          <h3 class="text-lg font-bold leading-6 text-gray-900">
            Daftar Faktur Pembayaran
          </h3>
        </div>
        <Datatable :paginated="false" :footer="false" class="mt-4">
          <template v-slot:thead>
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                >
                  No. Pembayaran
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                >
                  Kode Gudang
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                >
                  Tanggal
                </th>
                <template>
                  <th
                    scope="col"
                    class="px-6 py-3 text-right text-xs font-medium tracking-wider text-gray-500"
                  >
                    Total
                  </th>
                </template>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium tracking-wider text-gray-500"
                  colspan="2"
                ></th>
              </tr>
            </thead>
          </template>
          <template v-slot:tbody>
            <tbody v-if="hasInvoices > 0">
              <template v-for="(invoice, index) in dataPembayaran.invoices">
                <payment-row
                  :key="index"
                  :order="dataPembayaran"
                  :payment="invoice"
                  :collapsed="paymentAccordion.active !== invoice.id"
                  :any-active-row="paymentAccordion.active !== null"
                  :isRedirectToDetail="false"
                  :paymentAmountAlignRight="true"
                  v-on:toggle="togglePaymentAccordion(invoice.id)"
                  @verify-payment="(payment) => handleVerifyPayment(invoice)"
                  withVerification
                />
                <template
                  v-if="
                    paymentAccordion.active === invoice.id &&
                    !getLoadingPaymentMethods
                  "
                >
                  <template v-if="getPaymentPaymentMethods.data?.length > 0">
                    <payment-detail-row
                      v-for="(
                        paymentMethod, index
                      ) in getPaymentPaymentMethods.data"
                      :key="`payment-methods-${index}`"
                      :payment-method="paymentMethod"
                      :included="getPaymentPaymentMethods.included"
                      :invoice="getRelationships(getOrder, invoice.id)"
                      :paymentAmountAlignRight="true"
                      with-note
                      with-date
                      @verify-payment-method="
                        (paymentMethod) =>
                          handleVerifyPaymentMethod(paymentMethod, invoice)
                      "
                      withVerification
                    />
                  </template>
                  <template v-else>
                    <tr :key="index">
                      <td
                        class="whitespace-nowrap bg-gray-50 px-6 py-4 text-sm font-medium text-gray-900"
                        colspan="6"
                      >
                        Tidak ada metode pembayaran di invoice ini
                      </td>
                    </tr>
                  </template>
                </template>
              </template>
              <tr>
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  colspan="3"
                >
                  Total Faktur
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm font-medium text-gray-900"
                >
                  <template
                    v-if="
                      getOrder.data.relationships.payments?.data?.length <= 1
                    "
                  >
                    Rp.
                    {{
                      parseFloat(
                        getRelationships(
                          getOrder,
                          getOrder.data.relationships.payments.data[0].id
                        )?.attributes.order_price
                      ) | toCurrency
                    }}
                  </template>
                  <template v-else> 0 </template>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr class="bg-white">
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                  colspan="3"
                >
                  Belum ada riwayat transaksi
                </td>
              </tr>
            </tbody>
          </template>
        </Datatable>
      </div>

      <div>
        <div class="flex justify-between">
          <div>
            <button
              v-if="!dataPembayaran.is_valid_for_packing"
              @click="confirmation = !confirmation"
              type="button"
              class="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
            >
              Batalkan Faktur Penjualan
            </button>
          </div>
          <div>
            <button
              v-if="
                !dataPembayaran.is_valid_for_packing && dataPembayaran.is_paid
              "
              @click="validasi"
              id="bayar"
              class="ml-3 inline-flex rounded-md border border-gray-300 bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-600 hover:shadow-lg disabled:bg-gray-300 disabled:hover:shadow-none"
            >
              {{ 'Proses Gudang' }}
            </button>
            <router-link
              to="/kasir/pembayaran"
              class="ml-3 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg"
            >
              Kembali
            </router-link>
          </div>
        </div>
      </div>

      <!-- MODAL CONFIRMATION-->
      <transition name="fade">
        <div
          v-if="confirmation"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <modal
            @modal-action="successModal"
            link="/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist"
            :actions="false"
          >
            <template v-slot:svg>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <!-- Heroicon name: outline/check -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </template>
            <template v-slot:heading>
              <h3
                class="text-lg font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                {{
                  hasInvoices > 0
                    ? 'Sudah ada faktur pembayaran. Anda yakin ingin membatalkan?'
                    : 'Apakah anda yakin ingin membatalkan Penjualan?'
                }}
              </h3>
            </template>
            <template v-slot:actions>
              <button
                @click="cancelOrderFromCashier"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Ya
              </button>
              <button
                @click="confirmation = !confirmation"
                class="bg-white-600 focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
              >
                Kembali
              </button>
            </template>
          </modal>
        </div>
      </transition>

      <!-- CONFIRMATION PAYMENT -->
      <transition name="fade">
        <div
          v-if="confirmationPayment"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <modal
            @modal-action="successModal"
            link="/kasir/pembayaran/faktur-penjualan-pusat-ke-stockist"
            :actions="false"
          >
            <template v-slot:svg>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <!-- Heroicon name: outline/check -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </template>
            <template v-slot:heading>
              <h3
                class="text-lg font-semibold leading-6 text-gray-900"
                id="modal-title"
              >
                Apakah anda yakin ingin menghapus invoice
                {{ invoice.attributes.code }} ?
              </h3>
            </template>
            <template v-slot:actions>
              <button
                @click="cancelPayment"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Ya
              </button>
              <button
                @click="confirmationPayment = !confirmationPayment"
                class="bg-white-600 focus:outline-none inline-flex w-full justify-center rounded-md border border-gray-300 px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-white hover:shadow-lg focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
              >
                Kembali
              </button>
            </template>
          </modal>
        </div>
      </transition>

      <!-- MODAL SUCCESS-->
      <transition name="fade">
        <div
          v-if="success"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <base-modal
            :showing="true"
            @close="success = false"
            :showClose="success"
            size="max-w-sm"
          >
            <div
              class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100"
            >
              <!-- Heroicon name: outline/check -->
              <svg
                class="h-6 w-6 text-green-600"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                aria-hidden="true"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 13l4 4L19 7"
                />
              </svg>
            </div>
            <div class="mt-3 text-center sm:mt-5">
              <h3
                class="text-lg font-bold leading-6 text-gray-900"
                id="modal-title"
              >
                Data berhasil disimpan
              </h3>
            </div>
            <div class="mt-5 sm:mt-6">
              <button
                @click="success = false"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Selesai
              </button>
            </div>
          </base-modal>
        </div>
      </transition>

      <!-- MODAL ERROR -->
      <transition name="fade">
        <div
          v-if="failed"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <modal :actions="false">
            <template v-slot:svg>
              <div
                class="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100"
              >
                <!-- Heroicon name: outline/check -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-5 w-5 text-red-600"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fill-rule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clip-rule="evenodd"
                  />
                </svg>
              </div>
            </template>
            <template v-slot:heading>
              <h3
                class="text-lg font-bold leading-6 text-gray-900"
                id="modal-title"
              >
                {{ getError }}
              </h3>
            </template>
            <template #actions>
              <button
                @click.prevent="failed = false"
                class="focus:outline-none inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Selesai
              </button>
            </template>
          </modal>
        </div>
      </transition>
    </div>
    <!-- print -->
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :preview-modal="true"
      :enable-download="cetakPdf.download"
      :pdf-quality="2"
      :manual-pagination="false"
      :paginate-elements-by-height="5000"
      :filename="getOrder.data.attributes?.origin_code"
      :html2canvas="{
        scale: 4,
        logging: false,
        dpi: 192,
        letterRendering: true,
      }"
      pdf-orientation="portrait"
      ref="html2Pdf"
      pdf-format="a7"
      pdf-content-width="100mm"
    >
      <pdf-content slot="pdf-content" :payment="getPayment" />
    </vue-html2pdf>

    <loading v-if="isLoading"></loading>
  </section>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import dayjs from 'dayjs';
import Modal from '@/components/Modal.vue';
import BaseModal from '@/components/base/BaseModal.vue';
import Datatable from '@/components/base/Datatable';
import VueHtml2pdf from 'vue-html2pdf';
import PdfContent from '@/layouts/LayoutPrintInvoice.vue';
import { StorageService } from '@/services/storage.service';
import PaymentRow from '@/components/kasir/detail-penjualan/PaymentRow.vue';
import PaymentDetailRow from '@/components/kasir/detail-penjualan/PaymentDetailRow.vue';

export default {
  name: 'TambahTransaksiPembayaran',
  components: {
    Datatable,
    Modal,
    VueHtml2pdf,
    PdfContent,
    BaseModal,
    PaymentRow,
    PaymentDetailRow,
  },
  data() {
    return {
      is_detail: false,
      success: false,
      failed: false,
      openDetail: false,
      openListOrders: false,
      confirmation: false,
      confirmationPayment: false,
      cetakPdf: {
        cetak: false,
        download: false,
      },
      searchOrderDetail: '',
      dataPembayaran: {
        id: '',
        no_penjualan: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        saldo_order_awal: null,
        total_penjualan: null,
        is_paid: null,
        is_valid_for_packing: null,
        acc_payment_reason: null,
        office_type_creator: null,
        invoices: [],
        description: '',
        has_cashback: true,
      },
      metodePembayaran: [],
      invoice: {},
      paymentMethods: [
        {
          name: 'Cash',
          value: 'cash',
        },
        {
          name: 'Fee',
          value: 'fee',
        },
        {
          name: 'Rabat',
          value: 'rabat',
        },
        {
          name: 'Uang Jalan',
          value: 'uang_jalan',
        },
        {
          name: 'Bank',
          value: 'bank',
        },
      ],
      paymentAccordion: {
        active: null,
      },
    };
  },
  created() {
    this.setBank();

    const handleEscape = () => {
      this.success = false;
      this.failed = false;
    };
    this.setEscape(handleEscape);
  },
  computed: {
    ...mapGetters({
      isLoadingStockist: 'stockists/getLoading',
      getStockists: 'stockists/getStockists',
      getPayment: 'payments/getPayment',
      getBanksByOffice: 'banks/getBanksByOffice',
      getOrders: 'orders/getOrders',
      getLoading: 'orders/getLoading',
      getOrder: 'orders/getOrder',
      getError: 'orders/getError',
      getPaymentPaymentMethods: 'payments/getPaymentMethods',
      getLoadingPaymentMethods: 'payments/getLoadingPaymentMethods',
      isLoadingOrders: 'orders/getLoading',
      isLoadingPayments: 'payments/getLoading',
      isLoadingPaymentMethods: 'payment_methods/getLoading',
    }),
    isLoading() {
      return (
        this.isLoadingOrders ||
        this.isLoadingPayments ||
        this.isLoadingPaymentMethods
      );
    },
    isPaymentsValid() {
      // kalo ada yg blm di verifikasi, return false
      return this.getOrder.included?.every((it) => {
        if (it.type === 'payments') {
          return it.attributes.is_verified;
        } else {
          return true;
        }
      });
    },
    hasInvoices() {
      return this.dataPembayaran.invoices.length;
    },
    usedOrderBalance() {
      // jika sudah ada pembayaran
      if (this.hasInvoices || this.dataPembayaran.saldo_order_awal < 1) {
        return 0;
      }

      // // Jika saldo order < penjualan ? gunakan semua saldo : gunakan saldo - penjualan
      return Math.min(
        this.dataPembayaran.saldo_order_awal,
        this.dataPembayaran.total_penjualan
      );
    },
    getTotalPembayaran() {
      return this.sum(this.metodePembayaran, 'nominalPembayaran');
    },
    selisihPembayaran() {
      return (
        (this.dataPembayaran.total_penjualan || 0) -
        this.usedOrderBalance -
        this.sum(this.metodePembayaran, 'nominalPembayaran') -
        this.dataPembayaran.invoices.reduce(
          (prev, cur) => prev + cur.attributes.total_amount,
          0
        )
      );
    },
    isPaymentMethodEmpty() {
      return this.metodePembayaran.some(
        (metode) =>
          metode.jenisPembayaran === null || metode.nominalPembayaran === null
        // metode.nominalPembayaran === 0
      );
    },
    totalPembayaran() {
      return this.dataPembayaran.invoices.reduce(
        (prev, cur) => prev + cur.attributes.total_amount,
        0
      );
    },
    isCreatedByCurrentRole() {
      return (
        this.dataPembayaran.office_type_creator ===
        StorageService.getUser().current_role.toLowerCase()
      );
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchStockists: 'stockists/fetchStockists',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      fetchProducts: 'products/fetchProducts',
      fetchOrder: 'orders/fetchOrder',
      updateOrder: 'orders/updateOrder',
      createOrder: 'orders/createOrder',
      cancelOrder: 'orders/cancelOrder',
      createPayment: 'payments/createPayment',
      updatePayment: 'payments/updatePayment',
      updatePaymentMethod: 'payment_methods/updatePaymentMethod',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
      verifyPayment: 'payments/verifyPayment',
      verifyPaymentMethod: 'payment_methods/verifyPaymentMethod',
      createPaymentMethod: 'payment_methods/createPaymentMethod',
    }),
    setBank() {
      if (this.$route.params.id) {
        this.is_detail = true;
        this.dataPembayaran.id = this.$route.params.id;
      }

      this.fetchBanksByOffice({
        office_id: StorageService.getUser().office_id,
        fields: {
          'office-banks': 'office_bank_type,bank_name,account_number',
        },
      }).then((res) => {
        const officeBankType = new Set([
          ...res.data.data.map(
            (officeBank) => officeBank.attributes.office_bank_type
          ),
        ]);

        this.paymentMethods = this.paymentMethods.filter((method) => {
          return (
            [...officeBankType].includes(method.value) ||
            method.value == 'stockist'
          );
        });
        this.officeBanks = res.data.data;
        this.setOrder(this.dataPembayaran.id);
      });
    },
    async setOrder(id) {
      const response = await this.fetchOrder({
        id: id,
        include: 'destination-office,payments,origin-warehouse',
        fields: {
          orders:
            'payment_amount,cancel_warehouse_description,order_shipment,cashback,has_cashback,origin_code,origin_warehouse_code,area_code,final_price,payment_details,is_valid_for_packing,is_paid,destination-office,payments,acc_payment_reason,origin-warehouse,office_type_creator',
          offices: 'code,name,balance,order_balance',
          payments: 'code,total_amount,createdAt,is_verified,order_price,state',
          warehouses: 'code',
        },
      });
      const stockistOffice = this.getRelationships(
        response.data,
        response.data.data.relationships['destination-office'].data.id
      );

      this.dataPembayaran.no_penjualan =
        response.data.data.attributes.origin_code;
      this.dataPembayaran.kode_stockist = this.getRelationships(
        response.data,
        response.data.data.relationships['destination-office'].data.id
      ).attributes.code;
      this.dataPembayaran.nama_pemilik = this.getRelationships(
        response.data,
        response.data.data.relationships['destination-office'].data.id
      ).attributes.name;
      this.dataPembayaran.kode_gudang = this.getRelationships(
        response.data,
        response.data.data.relationships['origin-warehouse'].data.id
      ).attributes.code;
      this.dataPembayaran.wilayah_harga =
        response.data.data.attributes.area_code;
      this.dataPembayaran.saldo_awal = stockistOffice.attributes.balance;
      this.dataPembayaran.saldo_order_awal =
        stockistOffice.attributes.order_balance;
      this.dataPembayaran.total_penjualan =
        response.data.data.attributes.final_price;
      this.dataPembayaran.is_paid = response.data.data.attributes.is_paid;
      this.dataPembayaran.is_valid_for_packing =
        response.data.data.attributes.is_valid_for_packing;
      this.dataPembayaran.acc_payment_reason =
        response.data.data.attributes.acc_payment_reason;
      this.dataPembayaran.office_type_creator =
        response.data.data.attributes.office_type_creator;
      this.dataPembayaran.invoices = response.data.data.relationships[
        'payments'
      ].data.map((invoice) => {
        return this.getRelationships(response.data, invoice.id);
      });
      this.dataPembayaran.has_cashback =
        !!response.data.data.attributes.has_cashback;
      // this.metodePembayaran[0].nominalPembayaran = response.data.data.attributes.final_price - parseInt(response.data.data.attributes.payment_amount)

      if (this.dataPembayaran.is_paid) return;
      setTimeout(() => {
        this.$refs.nominal?.forEach((item, index) => {
          item.value = this.$options.filters.toCurrency(
            this.metodePembayaran[index].nominalPembayaran,
            {
              style: 'decimal',
              maxFractionDigit: 0,
            }
          );
        });
      }, 0);

      if (this.hasInvoices > 0) {
        this.metodePembayaran = [
          {
            jenisPembayaran: null,
            officeBank: null,
            nominalPembayaran: null,
          },
        ];
        return;
      }
      // autocomplete
      response.data.data.attributes.payment_details.forEach((item) => {
        const paymentMethod = {};

        if (item.balance_used) {
          paymentMethod.officeBank = null;
          paymentMethod.jenisPembayaran = this.paymentMethods.find((method) => {
            return method.value === item.balance_used;
          });
          paymentMethod.nominalPembayaran = item.payment_amount;

          this.metodePembayaran.push(paymentMethod);

          return true;
        }

        const officeBank = this.getBanksByOffice.data.find(
          (bank) => bank.id === item.office_bank_id
        );

        if (!officeBank) {
          paymentMethod.jenisPembayaran = null;
          paymentMethod.officeBank = null;
          paymentMethod.nominalPembayaran = null;
        } else {
          paymentMethod.jenisPembayaran = this.paymentMethods.find(
            (method) => method.value === officeBank.attributes.office_bank_type
          );
          paymentMethod.officeBank = {
            id: officeBank.id,
            name: officeBank.name,
          };
          paymentMethod.nominalPembayaran = item.payment_amount;
          paymentMethod.date = dayjs(item.transfer_date).format(
            'YYYY-MM-DDTHH:mm'
          );
        }
        this.metodePembayaran.push(paymentMethod);
      });
    },
    handleChangeMethod(index) {
      this.metodePembayaran[index].officeBank = null;
      this.metodePembayaran[index].date = dayjs().format('YYYY-MM-DDTHH:mm');

      if (this.metodePembayaran[index].jenisPembayaran.value == 'bank') {
        this.fetchBanksByOffice({
          office_id: StorageService.getUser().office_id,
          type: this.metodePembayaran[index].jenisPembayaran.value,
          isActive: true,
          fields: {
            'office-banks': 'office_bank_type,bank_name,account_number',
          },
        });
      }
    },
    getDataDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        const officeBankByType = this.officeBanks.filter(
          (officeBank) =>
            officeBank.attributes.office_bank_type ===
            param.jenisPembayaran.value
        );
        let data = {
          office_bank_id: param.officeBank
            ? param.officeBank.id
            : officeBankByType[0]?.id ?? null,
          payment_amount: parseInt(param.nominalPembayaran),
          transfer_date: param.date,
          balance_used:
            param.jenisPembayaran.value === 'stockist'
              ? param.jenisPembayaran.value
              : undefined,
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },
    async handleVerifyPayment(invoice) {
      await this.verifyPayment({
        paymentId: invoice.id,
      });

      await this.setOrder(this.dataPembayaran.id);

      if (this.getOrder.data.attributes.is_paid) {
        this.$router.push('/kasir/pembayaran');
      }
    },
    handleVerifyPaymentMethod(paymentMethod, invoice) {
      this.verifyPaymentMethod({ paymentMethodId: paymentMethod.id }).then(
        () => {
          this.setOrder(this.dataPembayaran.id);
          this.fetchPaymentPaymentMethods({
            id: invoice.id,
            include: 'office-bank',
            fields: {
              'payment-methods':
                'payment_amount,office-bank,balance_used,is_verified,transfer_date,description',
              'office-banks': 'bank_name',
            },
          });
        }
      );
    },
    addField() {
      this.metodePembayaran.push({
        jenisPembayaran: null,
        officeBank: null,
        nominalPembayaran: null,
      });
    },
    removeField(index) {
      this.metodePembayaran.splice(index, 1);
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
    },
    detailModal() {
      this.openDetail = !this.openDetail;
      this.clearData();
    },
    openPaymentConfirmation(invoice) {
      this.confirmationPayment = !this.confirmationPayment;
      this.invoice = invoice;
    },
    addPayment() {
      let payload = {
        data: {
          type: 'payments',
          attributes: {
            description: this.dataPembayaran.description,
          },
          relationships: {
            order: {
              data: {
                type: 'orders',
                id: this.dataPembayaran.id,
              },
            },
          },
        },
        include: 'order',
      };
      const orderPayload = {
        data: {
          type: 'orders',
          id: this.dataPembayaran.id,
          attributes: {
            has_cashback: this.dataPembayaran.has_cashback,
          },
        },
      };
      this.updateOrder(orderPayload);
      this.createPayment(payload).then(async (paymentResponse) => {
        if (!paymentResponse) {
          this.failed = true;
        } else {
          // bikin post request ke payment methods
          this.createPaymentMethods({
            paymentResponse: paymentResponse,
          });

          if (this.cetakPdf.cetak || this.cetakPdf.download) {
            this.$refs.html2Pdf.generatePdf();
            this.setBank();
          } else {
            this.setOrder(this.dataPembayaran.id);
            this.success = true;
          }
        }
      });
    },
    async createPaymentMethods({ paymentResponse }) {
      if (paymentResponse) {
        // Create Payment Methods
        const batchRequests = this.metodePembayaran.map((method) => {
          const officeBankByType = this.officeBanks.filter(
            (officeBank) =>
              officeBank.attributes.office_bank_type ===
              method.jenisPembayaran.value
          );
          return this.createPaymentMethod({
            paymentId: paymentResponse.data.data.id,
            officeBankId: method.officeBank
              ? method.officeBank.id
              : officeBankByType[0]?.id ?? null,
            attributes: {
              payment_amount: method.nominalPembayaran ?? 0,
              transfer_date: method.date ? dayjs(method.date).format() : null,
              ...(method.jenisPembayaran.value === 'stockist'
                ? { balance_used: 'stockist' }
                : {}),
            },
          });
        });

        return await Promise.all(batchRequests);
      }
    },
    clearData() {
      this.dataPembayaran = {
        id: '',
        no_penjualan: null,
        kode_stockist: null,
        nama_pemilik: null,
        kode_gudang: null,
        wilayah_harga: null,
        saldo_awal: null,
        total_penjualan: null,
        is_valid_for_packing: null,
        acc_payment_reason: null,
        office_type_creator: null,
        invoices: [],
      };
      (this.metodePembayaran = [
        {
          officeBank: null,
          jenisPembayaran: null,
          nominalPembayaran: null,
        },
      ]),
        (this.invoice = {});
    },
    cancelPayment() {
      const payload = {
        data: {
          type: 'payments',
          id: this.invoice.id,
          attributes: {
            deletedAt: new Date(),
          },
        },
      };

      this.updatePayment(payload).then((response) => {
        if (response) {
          this.confirmationPayment = !this.confirmationPayment;
          this.setOrder(this.dataPembayaran.id);
        } else {
          this.failed = true;
        }
      });
    },
    cancelOrderFromCashier() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPembayaran.id,
          attributes: {
            cancel_payment_description: 'Lorem Ipsum',
          },
        },
      };
      this.cancelOrder(payload).then((response) => {
        if (response) {
          this.$router.push('/kasir/pembayaran');
        } else {
          this.failed = true;
        }
      });
    },
    // input only accept number format only
    handleCurrency(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.metodePembayaran[index].nominalPembayaran = value;
      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    validasi() {
      const payload = {
        data: {
          type: 'orders',
          id: this.dataPembayaran.id,
          attributes: {
            is_valid_for_packing: true,
            canceled_by_user_id: null,
            canceled_from: null,
            acc_payment_reason: this.dataPembayaran.acc_payment_reason,
          },
        },
      };
      this.updateOrder(payload).then((response) => {
        if (response) {
          this.$router.push('/kasir/pembayaran');
        } else {
          this.failed = true;
        }
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    sum(data, key) {
      return data.reduce((a, b) => a + parseFloat(b[key] || 0), 0);
    },
    resetOfficeBank(index) {
      this.metodePembayaran[index].officeBank = null;
    },
    togglePaymentAccordion(id) {
      this.paymentAccordion.active =
        this.paymentAccordion.active === id ? null : id;

      if (this.paymentAccordion.active === id) {
        this.fetchPaymentPaymentMethods({
          id,
          include: 'office-bank',
          fields: {
            'payment-methods':
              'payment_amount,office-bank,balance_used,is_verified,transfer_date,transfer_date,description',
            'office-banks': 'bank_name',
          },
        });
      }
    },
    toggleCashback() {},
  },
};
</script>
