<template>
  <div
    class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
  >
    <h3 class="text-lg font-bold leading-6 text-gray-900">
      {{ title }}
    </h3>
    <div class="flex sm:mt-0 sm:ml-4">
      <router-link
        :to="disabledOrder.to"
        type="button"
        :title="disabledOrder.text"
        class="focus:outline-none inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
        :class="{
          'cursor-not-allowed opacity-50': !disabledOrder.auth,
        }"
      >
        <!-- Heroicon name: solid/mail -->
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-5 w-5"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
            clip-rule="evenodd"
          />
        </svg>
        {{ buttonTitle }}
      </router-link>
    </div>
  </div>
</template>
<script>
import { StorageService } from '@/services/storage.service';
import { mapGetters } from 'vuex';

export default {
  name: 'PenjualanHeader',
  props: {
    title: {
      type: String,
      default: 'Riwayat Transaksi',
    },
    buttonTitle: {
      type: String,
      default: 'Tambah Transaksi',
    },
    to: {
      type: String,
      default: 'transaksi-penjualan/tambah-transaksi-penjualan',
    },
  },
  computed: {
    ...mapGetters({
      getOffice: 'offices/getOffice',
    }),
    disabledOrder() {
      const me = StorageService.getUser();
      if (this.getOffice.data?.attributes?.is_order_disabled) {
        return {
          auth: false,
          to: '',
          text: 'Untuk saat ini kantor anda tidak dapat menambah transakasi, silakan kontak pemilik aplikasi ini',
        };
      }

      if (me.order_draft_count > 1) {
        return {
          auth: false,
          to: '',
          text: 'Anda tidak dapat menambah penjualan karena terdapat penjualan berstatus draft, lakukan validasi terlebih dahulu penjualan yang masih draft, setelah itu refresh halaman website',
        };
      }

      return {
        auth: true,
        to: this.to,
        text: '',
      };
    },
  },
};
</script>
