<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <BaseCard title="Data Pembayaran">
      <div class="flex flex-col space-y-4">
        <div class="flex items-center justify-end space-x-2">
          <base-select :expand="false" v-model="is_paid" @change="filterByPaid">
            <option :value="null">Semua</option>
            <option :value="true">Lunas</option>
            <option :value="false">Belum Lunas</option>
          </base-select>
          <base-search v-model="search" @input="debounceSearch" />
        </div>
        <div class="flex sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full align-middle sm:px-6 lg:px-8">
            <KasirTable
              view-as="stockist-center"
              @page-change="onPageChange"
              @click-row="onClickRow"
            />
          </div>
        </div>
      </div>
    </BaseCard>
  </div>
</template>

<script>
import { debounce } from 'debounce';
import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import BaseSearch from '@/components/base/Search.vue';
import dayjs from 'dayjs';
import { getRelationships } from '@/services/utils.service.js';
import KasirTable from '@/components/kasir/kasir-table.vue';

export default {
  name: 'Home',
  components: {
    KasirTable,
    BaseSearch,
  },

  data() {
    const origin_warehouse_id = StorageService.getUser().current_warehouse;
    return {
      search: null,
      openDetail: false,
      is_paid: false,
      data: {},
      origin_warehouse_id,
      selectedOrder: {},
      paymentAccordion: {
        active: null,
      },
      page: {
        number: 1,
      },
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      isLoading: 'orders/getLoading',
      getPaymentPaymentMethods: 'payments/getPaymentMethods',
      getLoadingPaymentMethods: 'payments/getLoadingPaymentMethods',
    }),
    orderParams() {
      return {
        fields: {
          // 'simple-orders': 'origin_code,warehouse_code',
          orders:
            'origin_code,destination-office,is_valid_for_packing,is_paid,destination_code,updatedAt,origin_warehouse_code,final_price,grand_total_price,cashback,payment_amount,canceled_by_user_id,canceled_from,order_status,area_name,order-details,grand_total_weight,origin-warehouse',
          offices: 'code,name,address,address2',
          warehouses: 'code',
        },
        include: 'destination-office,order-details,origin-warehouse,order',
        'filter[destination_office_category_id]': 4,
        'filter[origin_warehouse_id]':
          StorageService.getUser().current_warehouse,
        'filter[search]': this.search ?? undefined,
        'filter[is_paid]': this.is_paid ?? undefined,
      };
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      fetchPaymentPaymentMethods: 'payments/fetchPaymentPaymentMethods',
    }),
    getRelationships,
    debounceSearch: debounce(function () {
      this.fetchOrders({
        ...this.orderParams,
      });
    }, 300),
    onPageChange(page) {
      this.page.number = page;
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    filterByPaid() {
      this.fetchOrders({
        ...this.orderParams,
      });
    },
    async onClickRow(data) {
      this.$router.push('/kasir/pembayaran/' + data.attributes.order_id);
    },
    getRelationship(resource, id) {
      return resource.included.find((it) => it.id === id);
    },
    togglePaymentAccordion(id) {
      this.paymentAccordion.active =
        this.paymentAccordion.active === id ? null : id;

      if (this.paymentAccordion.active === id) {
        this.fetchPaymentPaymentMethods({
          id,
          include: 'office-bank',
          fields: {
            'payment-methods': 'payment_amount,office-bank,balance_used',
            'office-banks': 'bank_name',
          },
        });
      }
    },
  },
  created() {
    this.fetchOrders({
      ...this.orderParams,
    });
  },
};
</script>
