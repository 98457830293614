<template>
  <transition name="fade" class="z-20">
    <Modal2
      v-if="visible"
      @close-modal="closeModal"
      colorheading="bg-red-50"
      colorbody="bg-red-50"
    >
      <template v-slot:heading>
        <h3
          class="text-lg font-semibold leading-6 text-gray-900"
          id="modal-title "
        >
          Batalkan Laporan
        </h3>
      </template>
      <template v-slot:body>
        <div
          class="relative flex content-center items-center justify-center py-2"
        >
          <svg
            class="text-red-100"
            width="194"
            height="191"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M108.028 1.515c26.059 5.163 34.467 35.206 50.246 56.51 14.48 19.55 37.067 35.218 35.664 59.477-1.648 28.473-17.071 57.204-43.101 69.053-25.607 11.655-53.413-2.016-79.137-13.413-27.257-12.077-58.095-23.354-67.512-51.561-10.255-30.716-.982-65.558 20.224-90.077C44.76 7.977 77.462-4.541 108.028 1.515Z"
              fill="currentColor"
            />
          </svg>
          <div class="absolute flex w-full justify-center">
            <DocumentSuccess class="h-32" />
          </div>
        </div>
        <h3 class="text-lg leading-6 text-gray-900" id="modal-title">
          Laporan dengan Nomor
          <span class="font-bold">{{
            getOrderBundle.data.attributes.code
          }}</span>
          akan dibatalkan. Apakah anda yakin ingin melanjutkan?
        </h3>
      </template>
      <template v-slot:footer>
        <label fullwidth="" class="mb-2 block text-sm font-medium text-gray-700"
          >Ketik ulang kalimat berikut ini untuk mengkonfirmasi aksi pembatalan.
          <code class="bg-gray-100">
            {{ validationSentence }}
          </code>
        </label>
        <BaseInput
          v-model="confirmationSentence"
          fullwidth
          :classes="{ input: 'px-2' }"
        />
        <div class="w-full pt-4">
          <label for="comment" class="block text-sm font-medium text-gray-700"
            >Alasan pembatalan(wajib diisi)</label
          >
          <div class="mt-1">
            <textarea
              v-model="deleted_reason"
              rows="4"
              name="comment"
              id="comment"
              class="block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500 sm:text-sm"
            ></textarea>
          </div>
        </div>
        <div>
          <button
            @click="handleConfirmDelete"
            :disabled="
              confirmationSentence != validationSentence || !deleted_reason
            "
            :class="
              confirmationSentence != validationSentence || !deleted_reason
                ? 'cursor-not-allowed opacity-50'
                : ''
            "
            class="focus:outline-none w-full shrink justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
          >
            Ya, saya yakin
          </button>
          <button
            @click="closeModal"
            class="bg-white-600 inline-flex w-full justify-center rounded-md px-4 py-2 text-base font-medium text-gray-400 hover:bg-white hover:text-blue-400 sm:text-sm"
          >
            Kembali
          </button>
        </div>
      </template>
    </Modal2>
  </transition>
</template>
<script>
import BaseInput from '@/components/base/BaseInput.vue';
import { DocumentSuccess } from '@/assets/icons';
import ApiService from '@/services/api.service';
import Modal2 from '@/components/Modal2.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'ModalDeleteConfirmation',
  components: {
    BaseInput,
    DocumentSuccess,
    Modal2,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
  },
  emits: ['close', 'delete'],
  data: () => {
    return {
      confirmationSentence: '',
      deleted_reason: '',
    };
  },
  computed: {
    ...mapGetters({
      getOrderBundle: 'order_bundles/getOrderBundle',
    }),
    validationSentence() {
      return `Laporan dengan kode ${this.getOrderBundle.data.attributes.code} akan saya batalkan`;
    },
  },
  methods: {
    ...mapActions({
      createAlert: 'alert/createAlert',
    }),
    async handleConfirmDelete() {
      const res = await ApiService.post(
        `api/v1/order-bundles/${this.getOrderBundle.data.id}/-actions/cancel`,
        {
          deleted_reason: this.deleted_reason,
        }
      );
      this.createAlert({
        data: res ? 'Berhasil menghapus laporan' : 'Gagal menghapus laporan',
      });
      this.closeModal();
      this.confirmationSentence = '';
      this.$emit('delete');
    },
    closeModal() {
      this.$emit('close');
      this.confirmationSentence = '';
    },
  },
};
</script>
