<template>
  <div class="mx-4 pt-2 pb-4 lg:mx-8">
    <div class="">
      <BaseCard title="Laporan Penjualan" class="flex flex-col space-y-4">
        <div class="flex items-center justify-end gap-x-4">
          <!-- Start From -->
          <base-input
            type="date"
            label="Dari"
            with-label
            v-model="orderBundlesQuery.filter.fromDate"
            @change="onFilter"
          />
          <!-- End From -->
          <base-input
            type="date"
            label="Sampai"
            with-label
            v-model="orderBundlesQuery.filter.toDate"
            @change="onFilter"
          />
          <base-search
            label="Search"
            v-model="orderBundlesQuery.filter.search"
            @input="debounceSearch"
          />
          <base-button to="laporan-penjualan-sc/tambah-laporan-penjualan-sc">
            <Icon icon="heroicons:plus-20-solid" class="h-5 w-5" />
            Buat Laporan
          </base-button>
        </div>
        <div class="-flex sm:-mx-6 lg:-mx-8">
          <div class="inline-block min-w-full align-middle sm:px-6 lg:px-8">
            <OrderBundlesTable
              @click-row="tableDetails"
              @page-change="onPageChange"
            />
          </div>
        </div>
      </BaseCard>

      <!-- MODAL DETAIL -->
      <OrderBundlesDetailModal
        :visible="openDetail"
        @close="openDetail = false"
        @refresh="onRefresh"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import BaseInput from '@/components/base/BaseInput';
import OrderBundlesDetailModal from '@/components/order-bundles/OrderBundlesDetailModal';
import dayjs from 'dayjs';
import { beginningOfMonth, endOfMonth } from '@/services/utils.service';
import { StorageService } from '@/services/storage.service';
import BaseSearch from '@/components/base/Search.vue';
import BaseButton from '@/components/base/BaseButton.vue';
import OrderBundlesTable from '@/components/order-bundles/OrderBundlesTable.vue';

export default {
  name: 'Home',
  components: {
    BaseInput,
    BaseButton,
    BaseSearch,
    OrderBundlesTable,
    OrderBundlesDetailModal,
  },

  data() {
    const begin = beginningOfMonth(new Date());
    const end = endOfMonth(new Date());

    const startDate = dayjs(begin).format('YYYY-MM-DD');
    const endDate = dayjs(end).format('YYYY-MM-DD');

    return {
      orderBundlesQuery: {
        page: {
          size: 5,
          number: 1,
        },
        filter: {
          search: null,
          fromDate: null,
          toDate: null,
        },
      },
      loading: false,
      search: null,
      startDate,
      endDate,
      searchSC: null,
      selectedOffice: {
        id: null,
      },
      openDetail: false,
      dataLaporan: {},
      metodePembayaran: [
        {
          jenisPembayaran: null,
          officeBank: null,
          nominalPembayaran: null,
        },
      ],
      paymentMethods: [
        {
          name: 'Cash',
          value: 'cash',
        },
        {
          name: 'Fee',
          value: 'fee',
        },
        {
          name: 'Rabat',
          value: 'rabat',
        },
        {
          name: 'Uang Jalan',
          value: 'uang_jalan',
        },
        {
          name: 'Bank',
          value: 'bank',
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      getOrderBundles: 'order_bundles/getOrderBundles',
      getOrderBundle: 'order_bundles/getOrderBundle',
      getUser: 'auth/getUser',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOfficeById: 'offices/fetchOfficeById',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      createPaymentBundles: 'payment_bundles/createPaymentBundles',
      fetchOrderBundles: 'order_bundles/fetchOrderBundles',
      fetchOrderBundle: 'order_bundles/fetchOrderBundle',
      updateOrderBundle: 'order_bundles/updateOrderBundle',
      fetchTotalTransaction: 'order_bundles/fetchTotalTransaction',
      fetchOffices: 'offices/fetchOffices',
    }),
    loadOrderBundles() {
      this.fetchOrderBundles({
        pageNumber: this.orderBundlesQuery.page.number,
        pageSize: this.orderBundlesQuery.page.size,
        'filter[office_type]': 'stockist_center',
        ...(this.orderBundlesQuery.filter.fromDate
          ? { 'filter[fromDate]': this.orderBundlesQuery.filter.fromDate }
          : {}),
        ...(this.orderBundlesQuery.filter.toDate
          ? { 'filter[toDate]': this.orderBundlesQuery.filter.toDate }
          : {}),
        ...(this.orderBundlesQuery.filter.search
          ? { 'filter[search]': this.orderBundlesQuery.filter.search }
          : {}),
      });
      this.fetchTotalTransaction({
        params: {
          ...(this.orderBundlesQuery.filter.fromDate
            ? { fromDate: this.orderBundlesQuery.filter.fromDate }
            : {}),
          ...(this.orderBundlesQuery.filter.toDate
            ? { toDate: this.orderBundlesQuery.filter.toDate }
            : {}),
          office_id: this.getUser.office_id,
        },
      });
    },
    async setDefaultFilterDate() {
      this.orderBundlesQuery.filter.fromDate = dayjs(
        this.getUser.current_period.start_date
      ).format('YYYY-MM-DD');
      this.orderBundlesQuery.filter.toDate = dayjs(
        this.getUser.current_period.end_date
      ).format('YYYY-MM-DD');
    },
    resetPageOrderBundlesQuery() {
      this.orderBundlesQuery.page.number = 1;
    },
    onFilter() {
      this.resetPageOrderBundlesQuery();
      this.loadOrderBundles();
    },
    onPageChange(page) {
      this.orderBundlesQuery.page.number = page;
      this.loadOrderBundles();
    },
    onRefresh() {
      this.resetPageOrderBundlesQuery();
      this.loadOrderBundles();
    },
    debounceSearch: debounce(function () {
      this.resetPageOrderBundlesQuery();
      this.loadOrderBundles();
    }, 500),
    getDataDetails(params) {
      let dataDetail = [];
      params.map((param) => {
        const officeBankByType = this.officeBanks.filter(
          (officeBank) =>
            officeBank.attributes.office_bank_type ===
            param.jenisPembayaran.value
        );
        let data = {
          office_bank_id: param.officeBank
            ? param.officeBank.id
            : officeBankByType[0]?.id ?? null,
          payment_amount: parseInt(param.nominalPembayaran),
          is_using_balance: false, // default value for stockist
        };
        dataDetail.push(data);
      });
      return dataDetail;
    },
    bayarUtang(orderBundles, index) {
      const payload = {
        data: {
          type: 'payment-bundles',
          attributes: {
            payment_amount: this.getDataDetails(this.metodePembayaran)[index]
              .payment_amount,
            payment_type: 'non_balance',
          },
          relationships: {
            'order-bundle': {
              data: {
                type: 'order-bundles',
                id: orderBundles.id,
              },
            },
            'office-bank': {
              data: {
                type: 'office-banks',
                id: this.getDataDetails(this.metodePembayaran)[index]
                  .office_bank_id,
              },
            },
          },
        },
      };
      this.createPaymentBundles({ payload }).then(() => {
        this.openDetail = false;
        this.fetchOrderBundles({
          pageNumber: 1,
          pageSize: 5,
          officeType: 'stockist_center',
        });
      });
    },
    addField() {
      this.metodePembayaran.push({
        jenisPembayaran: null,
        officeBank: null,
        nominalPembayaran: null,
      });
    },
    removeField(index) {
      this.metodePembayaran.splice(index, 1);
    },
    tableDetails(data) {
      this.openDetail = !this.openDetail;

      this.fetchOrderBundle({
        id: data.id,
        include: 'paymentBundles,orders,office',
      });
    },
    getRelationships(resource, id) {
      let data = resource.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
    validasi(data) {
      const payload = {
        data: {
          type: 'order-bundles',
          id: data.id,
          attributes: {
            is_submitted: true,
          },
        },
      };
      this.updateOrderBundle(payload).then((response) => {
        if (response) {
          this.openDetail = false;
          this.fetchOrderBundles({
            pageNumber: 1,
            pageSize: 5,
            officeType: 'stockist_center',
          });
        }
      });
    },
    onChangeNominalPembayaran(e, index) {
      const value = Number(e.target.value.split(',')[0].replace(/\D/gi, ''));

      this.metodePembayaran[index].nominalPembayaran = value;
      e.target.value = this.$options.filters.toCurrency(value, {
        style: 'decimal',
        maxFractionDigit: 0,
      });
    },
    handleChangeMethod(index) {
      this.metodePembayaran[index].officeBank = null;

      if (this.metodePembayaran[index].jenisPembayaran) {
        this.fetchBanksByOffice({
          office_id: StorageService.getUser().office_id,
          type: this.metodePembayaran[index].jenisPembayaran.value,
          isActive: true,
        });
      }
    },
  },
  created() {
    this.setDefaultFilterDate();
    this.loadOrderBundles();
    this.fetchOfficeById({
      office_id: this.getUser.office_id,
    });

    this.fetchBanksByOffice({
      office_id: StorageService.getUser().office_id,
    }).then((res) => {
      const officeBankType = new Set([
        ...res.data.data.map(
          (officeBank) => officeBank.attributes.office_bank_type
        ),
      ]);

      this.paymentMethods = this.paymentMethods.filter((method) => {
        return [...officeBankType].includes(method.value);
      });
      this.officeBanks = res.data.data;
    });

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
