import { mapGetters, mapActions } from 'vuex';
import { StorageService } from '@/services/storage.service';
import dayjs from 'dayjs';

export const orderBundleCreateMixins = {
  data() {
    const me = StorageService.getUser();
    const default_report_name = `Lap-${me.office_code}-${dayjs().format(
      'DD-MM-YYYY'
    )}`;

    return {
      is_detail: false,
      success: false,
      failed: false,
      has_bundle: false,
      report: {
        id: null,
        name: default_report_name,
        description: default_report_name,
      },
      checkedOrders: [],
      orders: {
        page: {
          size: 5,
          number: 1,
        },
        filter: {
          origin_warehouse_id: null,
        },
      },
      payments: [
        {
          type: null,
          value: null,
          bank: null,
        }
      ],
    };
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getError: 'orders/getError',
      getOffices: 'offices/getOffices',
      getOffice: 'offices/getOffice',
      getBanksByOffice: 'banks/getBanksByOffice',
      getUser: 'auth/getUser',
      isOrderLoading: 'orders/getLoading',
      isOfficeLoading: 'offices/getLoading',
      isBankLoading: 'banks/getLoading',
    }),
    isLoading() {
      return this.isOrderLoading || this.isOfficeLoading || this.isBankLoading;
    },
    paymentMethods() {
      return [
        {
          name: 'Cash',
          value: 'cash',
        },
        {
          name: 'Bank',
          value: 'bank',
        },
        {
          name: 'Sisa Saldo Pembayaran',
          value: 'balance',
          renderName: () =>
            `Sisa Saldo Pembayaran (${this.$options.filters.toCurrency(
              this.getOffice.data?.attributes?.order_balance
            )})`,
        },
        {
          name: 'Lainnya',
          value: 'other',
        },
      ];
    },
    grandTotal() {
      return this.getOrders.data
        .filter((order) => this.checkedOrders.includes(order.relationships.order.data.id))
        .reduce(
          (total, order) =>
            (total += this.getSingleIncluded(this.getOrders, order.relationships.order.data.id).attributes.grand_total_report_price),
          0
        );
    },
    totalPayment() {
      return this.payments.reduce(
        (total, payment) => (total += payment.value),
        0
      );
    },
  },

  methods: {
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOffices: 'offices/fetchOffices',
      fetchOfficeById: 'offices/fetchOfficeById',
      createOrderBundle: 'order_bundles/createOrderBundle',
      fetchOrderBundle: 'order_bundles/fetchOrderBundle',
      updateOrderBundle: 'order_bundles/updateOrderBundle',
      fetchBanksByOffice: 'banks/fetchBanksByOffice',
      createPaymentBundles: 'payment_bundles/createPaymentBundles',
    }),
    async loadOrders({ ...params } = {}) {
      await this.fetchOrders({
        'page[limit]': this.orders.page.size,
        'filter[origin_warehouse_id]': this.orders.filter.origin_warehouse_id,
        'filter[order_status]': 'Dikirim,Selesai',
        // 'filter[has_bundle]': false,
        'fields[orders]':
          'origin_code,createdAt,grand_total_price,grand_total_report_price,destination-office,area',
        'fields[offices]': 'code',
        'fields[warehouses]': 'name',
        'fields[areas]': 'name',
        include: 'destination-office,area,order',
        ...params,
      });
      this.setPaymentDetails();
    },
    loadOriginOffice() {
      this.fetchOfficeById({
        office_id: this.getUser.office_id,
        params: {
          'fields[offices]': 'order_balance',
          'filter[office_category_id]': 3,
        },
      });
    },
    async loadPusatOfficeBanks() {
      await this.fetchOffices({
        'page[limit]': 1,
        'filter[code]': 'PUSAT',
        'fields[offices]': 'code',
        'filter[office_category_id]': 1,
        include: 'office',
      });

      await this.fetchBanksByOffice({
        office_id: this.getSingleIncluded(this.getOffices, this.getOffices.data[0].relationships.office.data.id).id,
        'filter[is_active]': true,
        'fields[office-banks]':
          'bank_name,account_name,account_number,office_bank_type',
      });
    },
    successModal() {
      this.success = !this.success;
      this.clearData();
    },
    failedModal() {
      this.failed = !this.failed;
      this.clearData();
    },
    detailModal() {
      this.openDetail = !this.openDetail;
      this.clearData();
    },
    onPageChangeOrders(page) {
      this.orders.page.number = page;
      this.loadOrders();
    },
    getOrdersRelationships() {
      return this.checkedOrders.map((id) => ({
        type: 'orders',
        id: id,
      }));
    },
    getPaymentBundlesAttribute() {
      return this.payments
        .filter((payment) => payment.type)
        .map((payment) => {
          const payload = {
            payment_type: 'non_balance',
            description: payment.description,
            payment_amount: payment.value
          }

          if (payment.bank) {
            payload.office_bank_id = payment.bank.id
          } else if (payment.type.name === 'Cash') {
            payload.office_bank_id = this.getBanksByOffice.data.find(bank => bank.attributes.office_bank_type === 'cash').id
          }

          return payload
        });
    },
    async sendReport() {
      const payload = {
        data: {
          type: 'order-bundles',
          attributes: {
            name: this.report.name,
            description: this.report.description,
          },
          relationships: {
            orders: {
              data: this.getOrdersRelationships(),
            },
            office: {
              data: {
                type: 'offices',
                id: StorageService.getUser().office_id,
              },
            },
          },
        },
      };
      if (this.is_detail) {
        payload.data.id = this.report.id;

        const response = await this.updateOrderBundle(payload);

        if (response) {
          await this.sendPaymentBundles({ orderBundle: response });

          this.success = true;
        } else {
          this.failed = true;
        }
      } else {
        const response = await this.createOrderBundle(payload);

        if (response) {
          await this.sendPaymentBundles({ orderBundle: response });

          this.success = true;
        } else {
          this.failed = true;
        }
      }
    },
    async sendPaymentBundles({ orderBundle }) {
      return await Promise.all(
        this.getPaymentsPayload({ orderBundle }).map(
          async (payload) => await this.createPaymentBundles({ payload })
        )
      );
    },
    getPaymentsPayload({ orderBundle }) {
      return this.payments
        .filter((payment) => payment.type)
        .map((payment) => {
          const payload = {
            payment_type: 'non_balance',
            description: payment.description,
            payment_amount: payment.value
          }

          if (payment.bank) {
            payload.office_bank_id = payment.bank.id
          } else if (payment.type.name === 'Cash') {
            payload.office_bank_id = this.getBanksByOffice.data.find(bank => bank.attributes.office_bank_type === 'cash').id
          }

          return {
            data: {
              type: 'payment-bundles',
              attributes: {
                payment_amount: payload.payment_amount,
                // description: payment.description, belum support
                payment_type: payload.payment_type,
              },
              relationships: {
                'order-bundle': {
                  data: {
                    type: 'order-bundles',
                    id: orderBundle.data.data.id,
                  },
                },
                'office-bank': {
                  data: {
                    type: 'office-banks',
                    id: payload.office_bank_id,
                  },
                },
              },
            },
          };
        });
    },
    getRelationships(resource, id) {
      let data = resource.included.find(function (el) {
        return el.id === id;
      });
      return data;
    },
    clearData() {
      this.checkedOrders = [];
      this.payments = [
        {
          type: null,
          bank: null,
          value: null,
          files: [],
        },
      ];
      this.report.name = null;
      this.report.description = null;
    },
  },
};
