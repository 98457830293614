<template>
  <Datatable
    :total="getOrders.meta.page.total"
    :perPage="getOrders.meta.page.perPage"
    :meta="getOrders.meta"
    :currentPage="getOrders.meta.page.currentPage"
    @pagechanged="onPageChange"
    :is-empty="!getOrders.data.length"
    cursor
  >
    <template v-slot:thead>
      <thead class="bg-gray-50">
        <tr>
          <th
            v-for="item in [
              {
                label: 'No. Faktur',
              },
              {
                label: 'Kode Stockist',
              },
              {
                label: 'Kode Gudang',
              },
              {
                label: 'Sisa Pembayaran',
                classes: 'text-right',
              },
              {
                label: 'Total Penjualan',
                classes: 'text-right',
              },
              {
                label: 'Status',
              },
              {
                label: 'Status Order',
              },
            ]"
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
            :key="item.label"
            :class="item.label"
          >
            {{ item.label }}
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody>
      <tbody v-if="getOrders.data.length">
        <tr
          class="cursor-pointer bg-white hover:bg-green-100"
          v-for="(data, dataIdx) in getOrders.data"
          :key="data.id"
          :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
          @click="clickRow(data)"
        >
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
          >
            <div class="text-sm text-gray-900">
              <span class="font-bold"> {{ data.attributes.origin_code }} </span
              >/
              <span class="text-gray-400">{{
                data.attributes.destination_code
              }}</span>
            </div>
            <div class="text-xs text-gray-500">
              {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
            </div>
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
            v-if="data.relationships['destination-office'].data"
          >
            {{
              getRelationships(data.relationships['destination-office'].data.id)
                .attributes.code
            }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
            v-if="data.relationships['destination-office'].data"
          >
            {{
              getRelationships(data.relationships['origin-warehouse']?.data.id)
                .attributes.code
            }}
          </td>
          <td
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
            v-if="data.relationships['destination-office'].data"
          >
            {{
              Math.max(
                getSingleIncluded(getOrders, data.relationships.order.data.id)
                  .attributes.grand_total_price -
                  getSingleIncluded(getOrders, data.relationships.order.data.id)
                    .attributes.payment_amount,
                0
              ) | toCurrency
            }}
          </td>
          <td
            v-if="data.relationships['destination-office'].data"
            class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
          >
            {{
              getSingleIncluded(getOrders, data.relationships.order.data.id)
                .attributes.grand_total_price | toCurrency
            }}
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            <base-badge v-if="data.attributes.is_paid"> Lunas </base-badge>
            <base-badge v-else color="red"> Belum Lunas </base-badge>
          </td>
          <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
            <span
              v-if="
                data.attributes.canceled_by_user_id &&
                data.attributes.canceled_from
              "
              class="inline-flex items-center rounded-full bg-red-100 px-3 py-1 text-sm font-medium text-red-700"
            >
              {{ data.attributes.order_status }}
            </span>
            <span
              v-else
              class="inline-flex items-center rounded-full bg-yellow-100 px-3 py-1 text-sm font-medium text-yellow-700"
            >
              {{ data.attributes.order_status }}
            </span>
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';
import Datatable from '@/components/base/Datatable.vue';
import BaseBadge from '@/components/base/BaseBadge';

export default {
  name: 'KasirTable',
  components: {
    Datatable,
    BaseBadge,
  },
  props: {
    viewAs: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
    }),
  },

  methods: {
    dayjs,
    onPageChange(val) {
      this.$emit('page-change', val);
    },
    clickRow(val) {
      this.$emit('click-row', val);
    },
    getRelationships(id) {
      let data = this.getOrders.included.filter(function (el) {
        return el.id === id;
      });
      return data[0];
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
