<template>
  <div class="flex mx-auto flex-row bg-white">
    <section slot="pdf-content" class="w-4/5 pl-2">
      <div
        v-if="payment?.data?.attributes"
        class="html2pdf__page-break flex w-full flex-col bg-white pl-6 pr-12"
      >
        <!-- Header -->
        <h1 class="text-md font-extrabold">PT. NATURAL NUSANTARA</h1>
        <div class="flex w-full flex-col justify-between">
          <div class="flex mb-2 w-full flex-col gap-y-1">
            <address class="text-xs">
              Jl. Ring Road Barat No. 72 Salakan Trihanggo Gamping Sleman
              Jogjakarta 55291 &nbsp; Telp:0274-6499191
            </address>
          </div>
          <h1
            class="relative bottom-1.5 border-t border-b border-dashed border-gray-700 pb-2 text-sm uppercase"
          >
            Tanda Terima Pembayaran
          </h1>
          <div
            v-if="payment.data?.attributes"
            class="flex flex-col md:flex-shrink"
          >
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-20 text-black">No Invoice</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{ payment.data.attributes.code }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-20 text-black">Tgl Invoice</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{ dayjs(payment.data.attributes.createdAt).format('ll LT') }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-20 text-black">Stockist</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{
                  included.find((it) => it.type == 'orders').attributes
                    .destination_office
                }}
                ({{
                  included.find((it) => it.type == 'orders').attributes
                    .destination_code
                }})
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-20 text-black">Waktu</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{
                  dayjs(payment.data.attributes.createdAt).format('DD/MM/YYYY')
                }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-20 text-black">Wilayah</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{
                  included.find((it) => it.type == 'orders').attributes
                    .area_name
                }}
                ({{
                  included.find((it) => it.type == 'orders').attributes
                    .area_code
                }})
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-20 text-black">No Faktur</p>
              <p>:</p>
              <p class="flex-shrink-0 font-normal text-gray-500">
                {{ getOrder.data.attributes?.origin_code }}
              </p>
            </div>
            <div class="flex flex-row items-center gap-x-1 text-xs">
              <p class="w-20 text-black">TTL Faktur</p>
              <p>:</p>
              <p class="flex-shrink font-normal text-gray-500">
                <template
                  v-if="getOrder.data.relationships.payments?.data.length > 0"
                >
                  0
                </template>
                <template v-else>
                  Rp.
                  {{
                    parseFloat(payment.data.attributes.order_price) | toCurrency
                  }}
                </template>
              </p>
            </div>
            <!-- Start Pembayaran -->
            <div class="flex flex-row items-center gap-x-1 pl-3 text-xs">
              <p class="w-20 text-black">Pembayaran</p>
              <p>:</p>
              <p class="flex-shrink font-normal text-gray-500"></p>
            </div>
            <div class="mb-2">
              <template v-for="(method, index) in getPaymentMethods">
                <div
                  v-if="method?.attributes?.bank_name"
                  class="pl-3"
                  :key="index"
                >
                  <div class="flex flex-row items-center gap-x-1 text-xs">
                    <p class="w-20 text-black">
                      &nbsp;&nbsp;&nbsp;&nbsp;{{
                        method.attributes.bank_name.slice(0, 8) + '..'
                      }}
                    </p>
                    <p>:</p>
                    <p class="flex-shrink-0 font-normal text-gray-500">
                      Rp.
                      {{
                        payment.data.attributes.payment_methods[index]
                          .payment_amount | toCurrency
                      }}
                      <small>
                        {{
                          dayjs(
                            payment.data.attributes.payment_methods[index]
                              .transfer_date
                          ).format('DD/MM/YYYY')
                        }}
                      </small>
                    </p>
                  </div>
                </div>
              </template>
            </div>
            <div
              class="flex relative bottom-1 flex-row items-center gap-x-1 border-t border-b border-dashed border-gray-700 pl-3 pb-2 text-xs"
            >
              <p class="w-20 text-black">Jml. Diterima</p>
              <p>:</p>
              <p class="flex-shrink font-normal text-gray-500">
                Rp. {{ payment.data.attributes.total_amount | toCurrency }}
              </p>
            </div>
            <div class="flex w-full flex-row items-start gap-x-1 pl-3 text-xs">
              <p class="w-20 text-black">Keterangan</p>
              <p>:</p>
              <p class="flex-shrink font-normal text-gray-500">
                {{ payment.data.attributes.description ?? '-' }}
              </p>
            </div>
            <div class="flex my-2 flex-row items-center gap-x-1 text-xs">
              <p class="font-normal text-gray-500">
                Yogyakarta
                {{
                  dayjs(payment.data.attributes.createdAt).format(
                    'DD/MM/YYYY LT'
                  )
                }}
              </p>
            </div>
          </div>
        </div>

        <!-- Bill to -->
        <div class="mt-6 w-full gap-x-6 pb-2 text-xs">
          <div
            class="flex w-full flex-col border-b border-dashed border-gray-700 pb-2"
          >
            <p class="italic">{{ me.name }}(Kasir)</p>
          </div>
          Saldo Belanja dan Pembayaran Per
          {{ dayjs().format('DD/MM/YYYY') }}:
          <br />

          Rp.
          {{
            getRelationships(
              getOrder,
              getOrder.data.relationships?.['destination-office'].data?.id
            )?.attributes.balance | toCurrency
          }}
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import dayjs from 'dayjs';
import { mapGetters } from 'vuex';

export default {
  components: {},

  props: {
    payment: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      dataPenjualan: {},
    };
  },
  computed: {
    ...mapGetters({
      getOrder: 'orders/getOrder',
      getPaymentMethods: 'payment_methods/getPaymentMethods',
      me: 'auth/getUser',
    }),
    included() {
      return (
        this.payment.included ?? [
          {
            type: 'orders',
            attributes: {
              destination_code: '',
              destination_office: '',
              area_name: '',
              area_code: 0,
            },
          },
          {
            type: 'offices',
            attributes: {
              balance: 0,
              destination_code: '',
              destination_office: '',
              area_name: '',
              area_code: 0,
            },
          },
        ]
      );
    },
  },
  methods: {
    dayjs,
    getRelationships(resource, id) {
      let data = resource.included?.find(function (el) {
        return el.id === id;
      });
      return data;
    },
    isSaldoStockist() {
      return this.payment.data.attributes.payment_methods.some(
        (it) => it.office_bank_id == null
      );
    },
  },
};
</script>
