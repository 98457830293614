<template>
  <Datatable
    :total="getOrderBundles.meta.page.total"
    :perPage="getOrderBundles.meta.page.perPage"
    :currentPage="getOrderBundles.meta.page.currentPage"
    @pagechanged="onPageChange"
  >
    <template v-slot:thead>
      <thead
        class="bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
      >
        <tr>
          <th
            scope="col"
            class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
          >
            Nomor Laporan
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider"
          >
            Total Laporan
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider"
          >
            Dikirim
          </th>
          <th
            scope="col"
            class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider"
          >
            Validasi Pusat
          </th>
        </tr>
      </thead>
    </template>
    <template v-slot:tbody>
      <div v-if="isLoading" class="flex items-center text-center">
        <loading></loading>
      </div>
      <template v-if="getOrderBundles.data?.length > 0">
        <tbody>
          <tr
            class="cursor-pointer bg-white hover:bg-green-100"
            v-for="(data, dataIdx) in getOrderBundles.data"
            :key="data.id"
            :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
            @click="tableDetails(data)"
          >
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-900">
              <p class="font-bold">
                {{ data.attributes.code }}
              </p>
              <p class="text-gray-500">
                {{ data.attributes.name ?? '-' }}
              </p>
              <p class="text-gray-500">
                {{ data.attributes.createdAt | formatDate }}
              </p>
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
            >
              {{ data.attributes.bundle_report_price | toCurrency }}
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
            >
              <base-badge
                :color="data.attributes.is_submitted ? 'green' : 'red'"
                >{{
                  data.attributes.is_submitted ? 'Dikirim' : 'Belum Terkirim'
                }}</base-badge
              >
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
            >
              <base-badge
                :color="data.attributes.is_validated ? 'green' : 'yellow'"
                >{{
                  data.attributes.is_validated
                    ? 'Validasi Pusat'
                    : 'Belum Tervalidasi'
                }}</base-badge
              >
            </td>
          </tr>
        </tbody>
        <tbody>
          <!-- Total Row -->
          <tr class="bg-white">
            <td class="whitespace-nowrap px-6 py-4 text-sm text-gray-500">
              Total Penjualan
            </td>
            <td
              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
            >
              {{
                getOrderBundle.data?.grand_total_order_bundle_price | toCurrency
              }}
            </td>
            <td colspan="2"></td>
          </tr>
        </tbody>
      </template>
      <tbody v-else>
        <tr class="bg-white">
          <td
            class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
            colspan="4"
          >
            Data tidak ditemukan
          </td>
        </tr>
      </tbody>
    </template>
  </Datatable>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'OrderBundlesTable',
  props: {},
  data() {
    return {
      // Your component data here
    };
  },
  computed: {
    ...mapGetters({
      getOrderBundles: 'order_bundles/getOrderBundles',
      getOrderBundle: 'order_bundles/getOrderBundle',
      isLoading: 'order_bundles/getLoading',
    }),
  },
  components: {
    // Your child components here
  },
  methods: {
    onPageChange(val) {
      this.$emit('page-change', val);
    },
    tableDetails(val) {
      this.$emit('click-row', val);
    },
  },
  created() {
    // Code to run when the component is created
  },
};
</script>

<style scoped>
/* Your component styles here */
</style>
