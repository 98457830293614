<template>
  <div class="-flex sm:-mx-6 lg:-mx-8">
    <div class="align-middle sm:px-6 lg:px-8">
      <Datatable
        :total="getOrders.meta.page.total"
        :perPage="getOrders.meta.page.perPage"
        :currentPage="getOrders.meta.page.currentPage"
        @pagechanged="onPageChangeOrder"
        :paginated="false"
        :is-empty="!getOrders.data.length"
      >
        <template v-slot:thead>
          <thead class="bg-blue-600">
            <tr>
              <th
                @click="onSort('createdAt')"
                scope="col"
                class="flex cursor-pointer gap-x-4 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Nomor Faktur
                <template v-if="ordersTable.sort.column === 'createdAt'">
                  <svg
                    v-if="ordersTable.sort.direction === 'asc'"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-4 w-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-4 w-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                </template>
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Wilayah Harga
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Keterangan
              </th>
              <th
                @click="onSort('is_valid_for_payment')"
                scope="col"
                class="cursor-pointer px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                <div class="flex justify-center gap-x-4">
                  Status Faktur
                  <template
                    v-if="ordersTable.sort.column === 'is_valid_for_payment'"
                  >
                    <svg
                      v-if="ordersTable.sort.direction === 'asc'"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-4 w-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                      />
                    </svg>
                    <svg
                      v-else
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="h-4 w-4"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M4.5 15.75l7.5-7.5 7.5 7.5"
                      />
                    </svg>
                  </template>
                </div>
              </th>
              <th
                @click="onSort('order_status')"
                scope="col"
                class="flex cursor-pointer justify-center gap-x-4 px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                STATUS
                <template v-if="ordersTable.sort.column === 'order_status'">
                  <svg
                    v-if="ordersTable.sort.direction === 'asc'"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-4 w-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-4 w-4"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M4.5 15.75l7.5-7.5 7.5 7.5"
                    />
                  </svg>
                </template>
              </th>
              <th
                scope="col"
                class="px-6 py-3 pr-24 text-right text-xs font-medium uppercase tracking-wider text-blue-100"
              >
                Belanja
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:tbody class="relative">
          <div v-if="isLoading" class="flex items-center text-center">
            <loading></loading>
          </div>
          <template v-if="getOrders.data.length">
            <tbody>
              <tr
                class="cursor-pointer bg-white hover:bg-blue-100"
                v-for="(data, dataIdx) in getOrders.data"
                :key="data.id"
                :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                @click.prevent="onDetail(data)"
              >
                <td
                  class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                >
                  <div class="text-sm font-bold text-gray-900">
                    {{ data.attributes.origin_code }}
                  </div>
                  <span class="text-xs">
                    {{ dayjs(data.attributes.updatedAt).format('ll LT') }}
                  </span>
                  <div class="text-xs text-gray-500">
                    {{ data.attributes.destination_code }}
                  </div>
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 pr-24 text-left text-sm text-gray-500"
                >
                  <template v-if="data.relationships?.area.data">
                    {{
                      getSingleIncluded(
                        getOrders,
                        data.relationships.area.data.id
                      ).attributes.name
                    }}
                    ({{
                      getSingleIncluded(
                        getOrders,
                        data.relationships.area.data.id
                      ).attributes.code
                    }})
                  </template>
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                >
                  {{ data.attributes.payment_method || '-' }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                >
                  <base-badge
                    :color="
                      data.attributes.is_valid_for_payment ? 'green' : 'yellow'
                    "
                    >{{
                      data.attributes.is_valid_for_payment ? 'Valid' : 'Draft'
                    }}</base-badge
                  >
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                >
                  <base-badge :color="getOrderStatusBadge(data).color">{{
                    getOrderStatusBadge(data).text
                  }}</base-badge>
                </td>
                <td
                  class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                >
                  {{ data.attributes.final_price | toCurrency }}
                </td>
              </tr>
            </tbody>
            <tbody class="bg-gray-50">
              <tr>
                <td
                  colspan="5"
                  class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                >
                  Total Penjualan
                </td>
                <td
                  class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                >
                  {{ getOrders.meta.total_price | toCurrency }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                  v-if="getOffice.data"
                >
                  Total Nilai Wilayah
                  {{
                    getOffice.data.relationships.area.data
                      ? `(${
                          getSingleIncluded(
                            getOffice,
                            getOffice.data.relationships.area.data.id
                          ).attributes.code
                        })`
                      : ''
                  }}
                </td>
                <td
                  class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                >
                  {{ getOrders.meta.total_report_price | toCurrency }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                >
                  Selisih
                </td>
                <td
                  class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                >
                  {{ getOrders.meta.price_gap | toCurrency }}
                </td>
              </tr>
              <tr>
                <td
                  colspan="5"
                  class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                >
                  Total Yang Dibayarkan
                </td>
                <td
                  class="whitespace-nowrap px-6 py-3 text-right text-sm text-gray-500"
                >
                  {{ getOrders.meta.total_report_price | toCurrency }}
                </td>
              </tr>
            </tbody>
          </template>
        </template>
      </Datatable>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import BaseBadge from '@/components/base/BaseBadge';
import Loading from '@/components/Loading.vue';
import dayjs from 'dayjs';
import { getListMonths, getListPastNYear } from '@/services/date.service.js';

export default {
  name: 'Home',
  components: {
    Datatable,
    Loading,
    BaseBadge,
  },

  data() {
    return {
      search: null,
      openDetail: false,
      ordersTable: {
        filter: {
          search: null,
          month: null,
          year: null,
        },
        page: {
          size: 5,
          number: 1,
        },
        sort: {
          column: 'createdAt',
          direction: 'desc',
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getOrder: 'orders/getOrder',
      getOrderLoading: 'orders/getLoading',
      getOfficeLoading: 'offices/getLoading',
      getOffice: 'offices/getOffice',
      getUser: 'auth/getUser',
    }),
    isLoading() {
      return false;
    },
    isDisabledOrder() {
      return this.getOffice.data?.attributes?.is_order_disabled;
    },
    availableMonthOptions: function () {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...getListMonths().map((month, index) => ({
          key: month,
          value: index + 1,
          label: month,
        })),
      ];
    },
    availablYearOptions: function () {
      return [
        {
          key: 'all',
          value: null,
          label: 'Semua',
        },
        ...getListPastNYear().map((year) => ({
          key: year,
          value: year,
          label: year,
        })),
      ];
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrders: 'orders/fetchOrders',
      fetchOrder: 'orders/fetchOrder',
      fetchOfficeById: 'offices/fetchOfficeById',
    }),
    loadOrders() {
      this.fetchOrders({
        pageSize: this.ordersTable.page.size,
        pageNumber: this.ordersTable.page.number,
        'filter[search]': this.ordersTable.filter.search,
        'filter[created_month]': this.ordersTable.filter.month,
        'filter[createdYear]': this.ordersTable.filter.year,
        'filter[origin_warehouse_id]': this.getUser.current_warehouse,
        'fields[orders]':
          'origin_code,destination_code,updatedAt,item_count,grand_total_weight,final_price,order_status,is_valid_for_payment,area',
        'fields[areas]': 'code,name',
        sort: `${this.ordersTable.sort.direction === 'desc' ? '-' : ''}${
          this.ordersTable.sort.column
        }`,
      });
    },
    resetPageOrdersTable() {
      this.ordersTable.page.number = 1;
    },
    resetOrdersTable() {
      this.ordersTable.page.number = 1;
      this.ordersTable.sort.column = 'createdAt';
      this.ordersTable.sort.direction = 'desc';
      this.ordersTable.filter.search = null;
    },
    getOrderStatusBadge(order) {
      const colors = {
        Draft: 'yellow',
        'Diproses Kasir': 'blue',
        'Diproses Gudang': 'indigo',
        Selesai: 'green',
        'Ada Susulan': 'purple',
        cancel: 'red',
      };

      return {
        color: colors[order.attributes.order_status],
        text: order.attributes.order_status,
      };
    },
    debounceSearch: debounce(function () {
      this.resetPageOrdersTable();
      this.loadOrders();
    }, 500),
    onPageChangeOrder(page) {
      this.ordersTable.page.number = page;
      this.loadOrders();
    },
    onSort(column) {
      this.ordersTable.sort.column = column;
      this.ordersTable.sort.direction =
        this.ordersTable.sort.direction === 'asc' ? 'desc' : 'asc';

      this.resetPageOrdersTable();
      this.loadOrders();
    },
    onFilter() {
      this.resetPageOrdersTable();
      this.loadOrders();
    },
    onDetail() {
      this.openDetail = !this.openDetail;
      this.$router.push('penjualan/transaksi-penjualan');
      window.scrollTo(0, 0);
    },
    refresh() {
      this.resetOrdersTable();
      this.loadOrders();
    },
  },
  created() {
    this.fetchOfficeById({
      office_id: this.getUser.office_id,
      params: {
        include: 'area',
      },
    });
    this.loadOrders();

    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
