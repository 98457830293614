var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto my-4 space-y-5 px-4 sm:px-4 lg:px-8"},[(_vm.loading.visible)?_c('loading',{attrs:{"text":_vm.loading.text}}):_vm._e(),_c('OrderCreateForm',{attrs:{"area":_vm.selectedArea},on:{"change-address":_vm.setupArea,"change-order-shipment":_vm.setupArea,"change-destination":_vm.setupArea},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),(_vm.canAddProduct)?[_c('OrderDetailTable',{attrs:{"create-order-payload":{
        order_type: 'sale',
        order_shipment: _vm.order.attributes.order_shipment,
        notes: _vm.order.attributes.notes,
        is_ppn_included: true,
        is_pre_order: false,
      },"origin-warehouse":{
        id: _vm.order.relationships.originWarehouse.id,
      },"destination-warehouse":{
        id: _vm.order.relationships.destinationWarehouse.id,
      },"origin-office":{
        id: _vm.order.relationships.originOffice.id,
        code: _vm.order.relationships.originOffice.attributes.code,
      },"origin-office-category-type":"Stockist Center","destination-office":{
        id:
          _vm.order.relationships.destinationOffice.attributes.office_id ??
          _vm.order.relationships.destinationOffice.id,
        code: _vm.rawDestinationOfficeCode,
      },"buyer-type":{
        id: _vm.order.relationships.buyerType.id,
        code: _vm.order.relationships.buyerType.attributes.code,
      },"area":{
        id: _vm.order.relationships.area.id,
        code: _vm.order.relationships.area.attributes.code,
      },"order-id":_vm.order.id,"min-grand-total":_vm.minOrderSpend,"with-cashback-warning":_vm.order.attributes.order_shipment !== 'delivery'},on:{"order-created":_vm.handleCreatedOrder,"created":_vm.handleCreatedOrderDetail,"order-updated":_vm.handleUpdatedOrder},model:{value:(_vm.orderDetails),callback:function ($$v) {_vm.orderDetails=$$v},expression:"orderDetails"}}),_c('OrderPromoList',{staticClass:"mb-5",attrs:{"order-details":_vm.orderDetailsForPromoList,"product-bundles":_vm.productBundles},on:{"change-selected-bundles":_vm.onChangeSelectedBundles,"change-choosen-bonuses":_vm.onChangeChoosenBonuses}}),_c('div',{staticClass:"mb-5"},[_c('OrderSummary',{attrs:{"totalbarang":_vm.orderDetails.length,"with-dpp":false,"with-ppn":false,"totalharga":_vm.totalPrice,"cashback":_vm.cashback,"with-cashback":_vm.getOrder.data.attributes.order_shipment != 'delivery',"balance":0}})],1),(_vm.orderDetails.length > 1)?_c('div',{staticClass:"space-y-5 rounded-lg bg-white p-4 sm:p-6 md:p-8"},[_vm._m(0),_c('PaymentMethodInlineForm',{attrs:{"payment-id":_vm.getIncludedByType(_vm.getOrder, 'payments')[0].id,"auto-store":""},model:{value:(_vm.paymentMethodsForm),callback:function ($$v) {_vm.paymentMethodsForm=$$v},expression:"paymentMethodsForm"}})],1):_vm._e(),_c('OrderCreatePrintFormVue',{model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),_c('OrderCreateActionForm',{attrs:{"cancel-redirect":"/penjualan/transaksi-penjualan","total-price":_vm.totalPrice,"min-order-spend":_vm.minOrderSpend,"total-payment-amount":_vm.totalPaymentAmount + _vm.cashback,"must-match-payment-amount":""},on:{"process":_vm.handleProcessOrder}}),_c('OrderDetailModal',{attrs:{"visible":_vm.success,"order":_vm.getOrder.data,"editable":true,"deleteable":false,"validationIgnorePaymentStatus":true,"with-bonus":""},on:{"close":_vm.handleClose,"edit":function($event){_vm.success = false},"refresh":_vm.handleClose}})]:_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h3',{staticClass:"text-lg font-bold leading-6 text-gray-900"},[_vm._v(" Metode Pembayaran ")])])
}]

export { render, staticRenderFns }