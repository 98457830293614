<template>
  <div class="mx-auto px-4 pb-12 sm:px-4 lg:px-8">
    <!-- Replace with your content -->
    <div class="flex justify-between">
      <h3 class="text-2xl font-semibold leading-6 text-gray-900">
        Selamat Datang, {{ me.name }}
      </h3>
      <span>Periode {{ date }}</span>
    </div>
    <!-- /End replace -->
    <main
      v-if="!loadingChart"
      class="my-4 grid grid-cols-1 gap-4 sm:grid-cols-2"
    >
      <BaseCard class="col-span-1">
        <h4 class="text-lg font-semibold leading-6 text-gray-900">
          Grafik Nominal Transaksi Penjualan ke Stockist
        </h4>
        <ChartComponent :chart-data="transactionAmount" />
        <ul class="mx-4 mt-2 list-disc text-xs text-gray-600">
          <li>
            Grafik menampilkan data transaksi penjualan ke stockist selama tahun
            2023
          </li>
          <li>
            Nominal transaksi penjualan dalam grafik diukur dalam rupiah (IDR)
          </li>
        </ul>
      </BaseCard>
      <BaseCard>
        <h4 class="text-lg font-semibold leading-6 text-gray-900">
          Jumlah Transaksi per Stockist
        </h4>
        <ChartComponent :chart-data="transactionCount" />
        <ul class="mx-4 mt-2 list-disc text-xs text-gray-600">
          <li>
            Grafik menampilkan data stockist dengan jumlah transaksi terbanyak,
            diurutkan dari yang paling banyak
          </li>
        </ul>
      </BaseCard>
    </main>
    <article class="flex flex-col gap-4">
      <BaseCard>
        <h4 class="mb-4 text-lg font-semibold leading-6 text-gray-900">
          Daftar Penjualan Terbaru
        </h4>
        <PenjualanTable />
      </BaseCard>
      <BaseCard>
        <h4 class="mb-4 text-lg font-semibold leading-6 text-gray-900">
          Daftar Restock Terbaru
        </h4>
        <RestockTable />
      </BaseCard>
    </article>
  </div>
</template>
<script>
import { StorageService } from '@/services/storage.service';
import ChartComponent from '@/components/dashboard/Chart.vue';
import BaseCard from '@/components/base/BaseCard.vue';
import PenjualanTable from '@/components/dashboard/table/PenjualanTable.vue';
import RestockTable from '@/components/dashboard/table/RestockTable.vue';
import dayjs from 'dayjs';
import ApiService from '@/services/api.service';

export default {
  components: {
    ChartComponent,
    BaseCard,
    PenjualanTable,
    RestockTable,
  },
  data() {
    return {
      date: dayjs().format('MMMM YYYY'),
      datas: [
        {
          title: 'Total Supplier',
          total: '30',
          svg: '',
        },
        {
          title: 'Barang Masuk',
          total: '300',
          svg: '',
        },
        {
          title: 'Pembelian',
          total: '250',
          svg: '',
        },
        {
          title: 'Data Gudang Daerah',
          total: '1240',
          svg: '',
        },
      ],
      me: StorageService.getUser(),
      transactionCount: {
        labels: [], //arr[str]
        datasets: [
          {
            label: 'Transaksi per Stockist',
            backgroundColor: '#eab308',
            data: [], //arr[int]
          },
        ],
      },
      transactionAmount: {
        labels: [],
        datasets: [
          {
            label: 'Total Transaksi',
            backgroundColor: '#3b82f6',
            data: [],
          },
        ],
      },
      loadingChart: false,
    };
  },
  async created() {
    try {
      await ApiService.get('/api/v1/offices/-actions/saleOrderGraph')
        .then((res) => {
          const transactionCount = res.data.map((it) => {
            return it.total_orders;
          });
          const labels = res.data.map((it) => {
            return it.name;
          });
          const transactionAmount = res.data.map((it) => {
            return Number(it.total_sales);
          });

          this.transactionCount.datasets[0].data = transactionCount;
          this.transactionAmount.datasets[0].data = transactionAmount;

          this.transactionCount.labels = labels;
          this.transactionAmount.labels = labels;
        })
        .catch(() => {
          this.loadingChart = false;
        });
      this.loadingChart = false;
    } catch (e) {
      console.error({ e });
    } finally {
      this.loadingChart = false;
    }
  },
};
</script>
