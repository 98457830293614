<template>
  <div class="m-2 rounded-2xl">
    <div class="py-2 sm:px-6">
      <section>
        <div
          class="border-b border-gray-200 pb-5 sm:flex sm:items-center sm:justify-between"
        >
          <h3 class="text-lg font-bold leading-6 text-gray-900">Laporan</h3>
          <div class="flex mt-3 sm:mt-0 sm:ml-4">
            <router-link
              to="laporan/tambah-laporan"
              type="button"
              class="focus:outline-none inline-flex items-center gap-2 rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              <!-- Heroicon name: solid/mail -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
                  clip-rule="evenodd"
                />
              </svg>
              Tambah Laporan
            </router-link>
          </div>
        </div>
        <div class="flex flex-col">
          <div class="flex justify-end">
            <base-search v-model="search" @input="debounceSearch" />
          </div>
          <div class="my-2 -flex sm:-mx-6 lg:-mx-8">
            <div
              class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8"
            >
              <Datatable
                :total="getOrderBundles.meta.page.total"
                :perPage="getOrderBundles.meta.page.perPage"
                :currentPage="getOrderBundles.meta.page.currentPage"
                @pagechanged="onPageChange"
              >
                <template v-slot:thead>
                  <thead class="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor Laporan
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Deskripsi
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Nomor PO
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Total Produk
                      </th>
                      <th
                        scope="col"
                        class="px-6 py-3 pr-24 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Tanggal Laporan
                      </th>
                    </tr>
                  </thead>
                </template>
                <template v-slot:tbody>
                  <tbody>
                    <tr
                      class="cursor-pointer bg-white hover:bg-green-100"
                      v-for="(data, dataIdx) in getOrderBundles.data"
                      :key="data.id"
                      :class="dataIdx % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                      @click="tableDetails(data)"
                    >
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                      >
                        {{ data.attributes.code }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ data.attributes.description }}
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        -
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        -
                      </td>
                      <td
                        class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                      >
                        {{ dayjs(data.attributes.createdAt).format('ll LT') }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </Datatable>
            </div>
          </div>
        </div>
      </section>

      <transition
        name="customFade"
        enter-active-class="fadeIn"
        leave-active-class="fadeOut"
      >
        <div
          v-if="openDetail"
          style="animation-duration: 0.3s"
          class="fixed inset-0 z-10 overflow-y-auto"
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div
            class="flex min-h-screen items-end justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0"
          >
            <div
              class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              class="hidden sm:inline-block sm:h-screen sm:align-middle"
              aria-hidden="true"
              >&#8203;</span
            >
            <div
              class="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-6xl sm:p-6 sm:align-middle"
            >
              <div class="flex mb-8 justify-between">
                <div>
                  <h3 class="text-lg font-bold leading-6 text-gray-900">
                    Detail Laporan
                  </h3>
                  <p class="mt-1 max-w-2xl text-sm text-gray-500">
                    Data laporan yang telah dibuat
                  </p>
                </div>
                <div>
                  <img
                    src="@/assets/images/logo.png"
                    alt=""
                    class="flex justify-end"
                  />
                </div>
              </div>
              <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl class="flex flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">No Laporan</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.code }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Tanggal Laporan
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{
                        dayjs(dataLaporan.attributes.updatedAt).format('ll LT')
                      }}
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">Deskripsi</dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      {{ dataLaporan.attributes.description }}
                    </dd>
                  </div>
                </dl>
                <dl class="flex mt-6 flex-row gap-x-4">
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Nama Stockist
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <!-- {{dataLaporan.relationships['destination-office'].data ? getRelationships(dataLaporan.relationships['destination-office'].data.id).attributes.name : '-'}} -->
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Alamat Stockist 1
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <!-- {{dataLaporan.relationships['destination-office'].data ? getRelationships(dataLaporan.relationships['destination-office'].data.id).attributes.address : '-'}} -->
                    </dd>
                  </div>
                  <div class="w-full">
                    <dt class="text-sm font-bold text-gray-700">
                      Alamat Stockist 2
                    </dt>
                    <dd class="mt-1 text-sm text-gray-900">
                      <!-- {{dataLaporan.relationships['destination-office'].data ? getRelationships(dataLaporan.relationships['destination-office'].data.id).attributes.address2 : '-'}} -->
                    </dd>
                  </div>
                </dl>
                <div class="my-6 sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">Daftar Barang</dt>
                </div>
                <div class="flex"></div>
              </div>
              <div class="flex justify-end">
                <button
                  @click="openDetail = !openDetail"
                  type="button"
                  class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Tutup
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { debounce } from 'debounce';
import Datatable from '@/components/base/Datatable';
import dayjs from 'dayjs';
import BaseSearch from '@/components/base/Search.vue';

export default {
  name: 'Home',
  components: {
    Datatable,
    BaseSearch,
  },

  data() {
    return {
      search: null,
      datas: [
        {
          title: 'Penjualan Terlapor',
          total: '15',
          svg: '',
        },
        {
          title: 'Penjualan Belum Terlapor',
          total: '5',
          svg: '',
        },
      ],
      openDetail: false,
      dataLaporan: {},
    };
  },

  computed: {
    ...mapGetters({
      getOrderBundles: 'order_bundles/getOrderBundles',
    }),
  },
  methods: {
    dayjs,
    ...mapActions({
      fetchOrderBundles: 'order_bundles/fetchOrderBundles',
    }),
    debounceSearch: debounce(function () {
      this.fetchOrderBundles({
        pageNumber: 1,
        pageSize: 5,
        search: this.search,
      });
    }, 300),
    onPageChange(page) {
      this.fetchOrderBundles({
        pageNumber: page,
        pageSize: 5,
        search: this.search,
      });
    },
    tableDetails(data) {
      this.openDetail = !this.openDetail;
      this.dataLaporan = data;
    },
  },
  created() {
    this.fetchOrderBundles({
      pageNumber: 1,
      pageSize: 5,
    });
    const handleEscape = () => {
      this.openDetail = false;
    };
    this.setEscape(handleEscape);
  },
};
</script>
