<template>
  <div>
    <Modal
      :showing="visible"
      @close="handleClose"
      :showClose="true"
      :backgroundClose="true"
      size="max-w-6xl"
    >
      <template v-if="getOrderBundle.data?.attributes">
        <div class="space-y-6">
          <div class="flex items-center justify-between">
            <div>
              <h3 class="text-lg font-bold leading-6 text-gray-900">
                Detail Laporan
              </h3>
              <p class="mt-1 max-w-2xl text-sm text-gray-500">
                Data laporan yang telah dibuat
              </p>
            </div>
            <div>
              <img src="@/assets/images/logo.png" alt="" class="w-32" />
            </div>
          </div>
          <div class="border-t border-gray-200 pt-6">
            <slot name="detail" :order-bundle="getOrderBundle">
              <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-6">
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">No Laporan</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ getOrderBundle.data.attributes.code }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">
                    Tanggal Laporan
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{
                      dayjs(getOrderBundle.data.attributes.updatedAt).format(
                        'll LT'
                      )
                    }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Validasi</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    <base-badge
                      :color="
                        getOrderBundle.data.attributes.is_validated
                          ? 'green'
                          : 'yellow'
                      "
                      >{{
                        getOrderBundle.data.attributes.is_validated
                          ? 'Validasi Pusat'
                          : 'Belum Tervalidasi'
                      }}</base-badge
                    >
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Nama Laporan</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ getOrderBundle.data.attributes.name || '-' }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">
                    Deskripsi Laporan
                  </dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    {{ getOrderBundle.data.attributes.description || '-' }}
                  </dd>
                </div>
                <div class="sm:col-span-2">
                  <dt class="text-sm font-bold text-gray-700">Dikirim</dt>
                  <dd class="mt-1 text-sm text-gray-900">
                    <base-badge
                      :color="
                        getOrderBundle.data.attributes.is_submitted
                          ? 'green'
                          : 'red'
                      "
                      >{{
                        getOrderBundle.data.attributes.is_submitted
                          ? 'Dikirim'
                          : 'Belum Terkirim'
                      }}</base-badge
                    >
                  </dd>
                </div>
                <div class="sm:col-span-6">
                  <dt class="text-sm font-bold text-gray-700">
                    Daftar Faktur Penjualan
                  </dt>
                  <dd class="mt-2">
                    <Datatable :paginated="false" :footer="false">
                      <template v-slot:thead>
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Nomor Faktur
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Wilayah Harga
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Total Faktur
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-right text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Total Pembayaran
                            </th>
                          </tr>
                        </thead>
                      </template>
                      <template v-slot:tbody>
                        <tbody>
                          <tr
                            class="bg-white"
                            :class="index % 2 === 0 ? 'bg-white' : 'bg-gray-50'"
                            v-for="(data, index) in getOrderBundle.data
                              .relationships['orders'].data"
                            :key="index"
                          >
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm font-medium text-gray-900"
                            >
                              <div class="text-sm font-bold text-gray-900">
                                {{
                                  getSingleIncluded(getOrderBundle, data.id)
                                    .attributes.origin_code
                                }}
                              </div>
                              <span class="text-xs">
                                {{
                                  dayjs(
                                    getSingleIncluded(getOrderBundle, data.id)
                                      .attributes.updatedAt
                                  ).format('ll LT')
                                }}
                              </span>
                              <div class="text-xs text-gray-500">
                                {{
                                  getSingleIncluded(getOrderBundle, data.id)
                                    .attributes.destination_code
                                }}
                              </div>
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-sm text-gray-500"
                            >
                              {{
                                dayjs(
                                  getSingleIncluded(getOrderBundle, data.id)
                                    .attributes.updatedAt
                                ).format('ll LT')
                              }}
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                getSingleIncluded(getOrderBundle, data.id)
                                  .attributes.item_count
                              }}
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                getSingleIncluded(getOrderBundle, data.id)
                                  .attributes.grand_total_report_price
                                  | toCurrency
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colspan="3"
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              Total
                            </td>
                            <td
                              class="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500"
                            >
                              {{
                                getOrderBundle.data.attributes
                                  .bundle_report_price | toCurrency
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </Datatable>
                  </dd>
                  <dd class="mt-4">
                    <Datatable :paginated="false" :footer="false">
                      <template v-slot:thead>
                        <thead class="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              class="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Jumlah Pembayaran
                            </th>
                            <th
                              scope="col"
                              class="px-6 py-3 text-center text-xs font-medium uppercase tracking-wider text-gray-500"
                            >
                              Status
                            </th>
                            <th></th>
                          </tr>
                        </thead>
                      </template>
                      <template v-slot:tbody>
                        <tbody>
                          <!-- eslint-disable-next-line vue/no-template-key -->
                          <template
                            v-for="(data, index) in getOrderBundle.data
                              .relationships['paymentBundles'].data"
                          >
                            <!-- eslint-disable-next-line vue/require-v-for-key -->
                            <tr
                              class="cursor-pointer bg-white hover:bg-gray-100"
                              :class="
                                index % 2 === 0 ? 'bg-white' : 'bg-gray-50'
                              "
                              @click="
                                openPaymentBundle(
                                  getSingleIncluded(getOrderBundle, data.id)
                                )
                              "
                            >
                              <td
                                class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                              >
                                {{
                                  getSingleIncluded(getOrderBundle, data.id)
                                    .attributes.payment_amount | toCurrency
                                }}
                              </td>
                              <td
                                class="whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                              >
                                <base-badge
                                  :color="
                                    getSingleIncluded(getOrderBundle, data.id)
                                      .attributes.is_verified
                                      ? 'green'
                                      : 'red'
                                  "
                                >
                                  {{
                                    getSingleIncluded(getOrderBundle, data.id)
                                      .attributes.is_verified
                                      ? 'Terverifikasi'
                                      : 'Belum Terverifikasi'
                                  }}
                                </base-badge>
                              </td>
                              <td
                                class="w-4 whitespace-nowrap px-6 py-4 text-center text-sm text-gray-500"
                              >
                                <svg
                                  :class="{
                                    'rotate-180':
                                      selectedPaymentBundle.id == data.id,
                                  }"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke-width="1.5"
                                  stroke="currentColor"
                                  class="h-6 w-6 transition-all"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                                  />
                                </svg>
                              </td>
                            </tr>
                            <!-- eslint-disable-next-line vue/require-v-for-key -->
                            <tr v-if="selectedPaymentBundle.id == data.id">
                              <td
                                class="whitespace-nowrap px-6 py-4 text-left text-sm text-gray-500"
                                colspan="3"
                              >
                                <PaymentMethodInlineForm
                                  v-model="payments"
                                  :methods="paymentMethods"
                                  :withAppendMethod="false"
                                  :withDeleteMethod="false"
                                  @change-nominal="onChangeNominal"
                                  @change-bank="onChangeBank"
                                  @change-payment-type="onChangePaymentType"
                                />
                              </td>
                            </tr>
                          </template>
                        </tbody>
                      </template>
                    </Datatable>
                  </dd>
                </div>
                <div
                  v-if="
                    getOrderBundle.data.attributes.total_payment <
                    getOrderBundle.data.attributes.bundle_report_price
                  "
                  class="sm:col-span-6"
                >
                  <dt class="text-sm font-bold text-gray-700">
                    Metode Pembayaran
                  </dt>
                  <dt class="text-xs text-gray-500">
                    Terdapat Utang Pada Laporan Ini, Silakan Tambah Metode
                    Pembayaran.
                  </dt>
                  <dd class="mt-2">
                    <PaymentMethodInlineForm
                      v-model="payments"
                      :methods="paymentMethods"
                    >
                      <template #action>
                        <base-button
                          :disabled="!hasPayment"
                          @click="handleSubmitPayment"
                          >Simpan</base-button
                        >
                      </template>
                    </PaymentMethodInlineForm>
                  </dd>
                </div>
              </dl>
            </slot>
          </div>
          <div class="flex justify-between border-t border-gray-200 pt-5">
            <div>
              <CancelButton
                v-if="!getOrderBundle.data.attributes.is_validated"
                @click="handleDeleteModal"
              />
            </div>
            <div class="flex">
              <button
                v-if="!getOrderBundle.data.attributes.is_submitted"
                @click="handleValidate"
                class="focus:outline-none mr-2 inline-flex items-center rounded-md border border-transparent bg-green-600 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-green-700 focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="mr-2 h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z"
                  />
                </svg>
                Validasi
              </button>
              <button
                @click="handleClose"
                type="button"
                class="focus:outline-none inline-flex justify-end rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Tutup
              </button>
            </div>
          </div>
        </div>
      </template>
    </Modal>
    <ModalDeleteConfirmation
      :visible="isModalDeleteVisible"
      @close="handleDeleteModal"
      @delete="
        () => {
          handleClose();
          handleRefresh();
        }
      "
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import dayjs from 'dayjs';
import Modal from '@/components/base/BaseModal';
import Datatable from '@/components/base/Datatable';
import BaseBadge from '@/components/base/BaseBadge';
// import BaseInput from '@/components/base/BaseInput';
import BaseButton from '@/components/base/BaseButton';
import PaymentMethodInlineForm from '@/components/payment/PaymentMethodInlineForm.vue';
import CancelButton from '@/components/button/CancelButton.vue';
import ModalDeleteConfirmation from '@/components/order-bundles/modal-delete-confirmation.vue';
import { orderCreateMixin } from '@/mixins/order/order-create.mixin';

export default {
  components: {
    Modal,
    BaseBadge,
    Datatable,
    PaymentMethodInlineForm,
    BaseButton,
    CancelButton,
    ModalDeleteConfirmation,
    // BaseInput
  },
  mixins: [orderCreateMixin],
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close', 'refresh'],
  data() {
    return {
      payments: [
        {
          type: null,
          bank: null,
          value: null,
          files: [],
        },
      ],
      isModalDeleteVisible: false,
      selectedPaymentBundle: {},
      selectedOfficeBank: {},
    };
  },
  watch: {
    visible(value) {
      if (value) {
        this.resetPayments();
      }
    },
  },
  computed: {
    ...mapGetters({
      getOrderBundle: 'order_bundles/getOrderBundle',
      getPaymentBundle: 'payment_bundles/getData',
      getBanksByOffice: 'banks/getBanksByOffice',
      getOffice: 'offices/getOffice',
      getUser: 'auth/getUser',
    }),
    paymentMethods() {
      return [
        {
          name: 'Cash',
          value: 'cash',
        },
        {
          name: 'Bank',
          value: 'bank',
        },
        {
          name: 'Sisa Saldo Pembayaran',
          value: 'balance',
          renderName: () =>
            `Sisa Saldo Pembayaran (${this.$options.filters.toCurrency(
              this.getOffice.data?.attributes?.order_balance
            )})`,
        },
        {
          name: 'Lainnya',
          value: 'other',
        },
      ];
    },
    hasPayment() {
      return this.payments.some((payment) => payment.type);
    },
  },
  methods: {
    dayjs,
    ...mapActions({
      createAlert: 'alert/createAlert',
      fetchPaymentBundles: 'payment_bundles/fetchPaymentBundles',
      fetchPaymentBundle: 'payment_bundles/fetchPaymentBundle',
      updatePaymentBundles: 'payment_bundles/updatePaymentBundles',
      createPaymentBundles: 'payment_bundles/createPaymentBundles',
      updateOrderBundle: 'order_bundles/updateOrderBundle',
    }),
    onChangePaymentType() {},
    onChangeBank() {
      return this.onChangePaymentBundles().then((response) => {
        this.createAlert({
          data: response
            ? 'Berhasil memperbarui bank'
            : 'Gagal memperbarui bank',
        });
      });
    },
    async onChangeNominal() {
      return this.onChangePaymentBundles().then((response) => {
        this.createAlert({
          data: response
            ? 'Berhasil memperbarui nominal'
            : 'Gagal memperbarui nominal',
        });
      });
    },
    async onChangePaymentBundles() {
      return await this.updatePaymentBundles({
        id: this.selectedPaymentBundle.id,
        payload: {
          data: {
            type: 'payment-bundles',
            id: this.selectedPaymentBundle.id,
            attributes: {
              payment_amount: this.payments[0].value,
            },
            relationships: {
              'office-bank': {
                data: {
                  type: 'office-banks',
                  id: this.payments[0].bank.id,
                },
              },
            },
          },
        },
      });
    },
    handleDeleteModal() {
      this.isModalDeleteVisible = !this.isModalDeleteVisible;
    },
    async openPaymentBundle(paymentBundle) {
      if (paymentBundle.id === this.selectedPaymentBundle.id) {
        this.selectedPaymentBundle = {};
        return;
      }
      const response = await this.fetchPaymentBundle({
        id: paymentBundle.id,
        params: {
          include: 'office-bank',
        },
      });
      const id = response.data.data.relationships['office-bank'].data.id;
      const officeBank = response.data.included.find((it) => it.id == id);

      this.payments = [
        {
          type: {
            id: undefined,
            name: 'Bank',
            value: 'bank',
          },
          bank: {
            id: officeBank.id,
            name: officeBank.attributes.name,
          },
          value: this.getPaymentBundle.data.attributes.payment_amount,
          files: [],
        },
      ];

      this.selectedOfficeBank = this.getPaymentBundle.included.find(
        (it) => it.type == 'office-banks'
      );
      this.selectedPaymentBundle = paymentBundle;
    },
    resetPayments() {
      this.payments = [
        {
          type: null,
          bank: null,
          value: null,
          files: [],
        },
      ];
    },
    getPaymentPayload() {
      return this.payments
        .filter((payment) => payment.type)
        .map((payment) => {
          const officeBankId = payment.bank
            ? payment.bank.id
            : this.getBanksByOffice.data.find(
                (bank) =>
                  bank.attributes.office_bank_type ===
                  payment.type.name?.toLowerCase()
              )?.id || null;
          const paymentType =
            payment.type.value === 'balance' ? 'balance' : 'non_balance';
          return {
            data: {
              type: 'payment-bundles',
              attributes: {
                payment_amount: payment.value,
                description: payment.description,
                payment_type: paymentType,
              },
              relationships: {
                'order-bundle': {
                  data: {
                    type: 'order-bundles',
                    id: this.getOrderBundle.data.id,
                  },
                },
                'office-bank': {
                  data: {
                    type: 'office-banks',
                    id: officeBankId,
                  },
                },
              },
            },
          };
        });
    },
    handleClose() {
      this.$emit('close');
    },
    handleRefresh() {
      this.$emit('refresh');
    },
    async handleSubmitPayment() {
      const res = await Promise.all(
        this.getPaymentPayload().map(
          async (payload) => await this.createPaymentBundles({ payload })
        )
      );

      if (res) {
        this.$emit('close');
        this.$emit('refresh');
      }
    },
    async handleValidate() {
      const payload = {
        data: {
          type: 'order-bundles',
          id: this.getOrderBundle.data.id,
          attributes: {
            is_submitted: true,
          },
        },
      };

      const res = await this.updateOrderBundle(payload);

      if (res) {
        this.$emit('close');
        this.$emit('refresh');
      }
    },
  },
  created() {
    this.setupPaymentMethodTypes({ officeId: this.getUser.office_id });
  },
};
</script>
